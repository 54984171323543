import api from '@/base/utils/request'

export const getActivityFormList = (data) => {
  return api({url:'admin/cyc_activity/ActivityForm/index', method: 'post', data})
}

export const deleteActivityForm = (data) => {
  return api({url:'/admin/cyc_activity/ActivityForm/delete', method: 'post', data, notCancel: true})
}

export const getActivityFormDetail = (data) => {
  return api({url:'/admin/cyc_activity/ActivityForm/detail', method: 'post', data})
}

export const setActivityFormDetail = (data) => {
  return api({url:'/admin/cyc_activity/ActivityForm/save', method: 'post', data})
}