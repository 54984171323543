<template>
  <div :style="{
    marginBottom: myConfig.normal.marginBottom + 'px'
  }" class="st-form-item st-input organization">
    <base-form-item :label="myConfig.normal.label" :required="myConfig.normal.required"
      :vertical="formType === 'id_card_photo' || myConfig.normal.inputType === 'textarea'"
    :description="myConfig.normal.description">
      <div v-if="formType !== 'id_card_photo'" class="form-item-right" :class="{vertical: myConfig.normal.inputType === 'textarea'}">
        <template v-if="type === 'multi-select' && !pricing">
          <!-- TODO: 多选项 -->
          <!-- <el-checkbox-group v-if="myOptions.length" :value="[] || [myOptions[0]]" class="input-placeholder flex" style="flex-wrap: wrap;">
            <el-checkbox :label="option || '选项名称'" v-for="(option, index) in myOptions" :key="index" />
          </el-checkbox-group> -->

          <div class="flex-wrap">
            <el-tag style="    white-space: pre-wrap;
    min-height: 32px;
    line-height: normal;
    height: fit-content;
    padding: 6px 10px;"
                    v-for="(item, index) in myOptions"
                    :key="index"
                    effect="plain">
              {{ item || '选项名称' }}
            </el-tag>
          </div>
        </template>
        <!-- value 是旧的，placeholder 是新的 -->
        <div class="input-placeholder" v-else-if="formType !== 'avatar' && !pricing">{{myConfig.normal.value || myConfig.normal.placeholder}}</div>
        <div v-else-if="pricing" class="flex-wrap">
          <el-tag style="min-width: 88px;"
              v-for="item in myOptions"
              :key="item.id"
              effect="plain">
            {{ item.text || '选项名称' }}<template v-if="item.pay_amount > 0">（{{item.pay_amount}}{{item.pay_type ? '积分' : '元'}}）</template>
          </el-tag>
        </div>
        <div v-else class="input-placeholder">
          <el-image class="input-avatar" :src="defaultAvatar" fit='cover'></el-image>
        </div>

        <div v-if="type === 'select' && !pricing" class="el-icon-arrow-right input-icon"></div>
      </div>
      <div class="form-item-bottom" v-else>
        <div class="upload-box">
          <el-image class="upload-image" :src="require('../../../assets/images/image-upload.png')"
            fit="cover">
          </el-image>
          <div class="upload-tip">正面</div>
        </div>
        <div class="upload-box">
          <el-image class="upload-image" :src="require('../../../assets/images/image-upload.png')"
            fit="cover">
          </el-image>
          <div class="upload-tip">反面</div>
        </div>
      </div>
    </base-form-item>
  </div>
</template>

<script>
import BaseFormItem from "./BaseFormItem";
export default {
  name: "StInput",
  components: { BaseFormItem },
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
    type: {
      type: String,
      default: "input",
    },
    formType: {
      type: String,
      default: "base-input",
    },
  },
  data() {
    return {
      defaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/wxapps/icons/avatar1.png", //默认头像
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginBottom: 0,
          required: 1,
          label: "标题内容",
          value: "",
          placeholder: "请输入",
          inputType: "text",
          validate: "",
          min: 1, // 最小值，当数字类型时
          max: 8, // 最大值
          description: '',
          maxlength: 0, // 最大字数
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    myOptions() {
      return this.config?.normal?.options || [];
    },
    pricing() {
      return this.myOptions.length && this.myOptions[0].pay_type !== undefined
    }
  },
  created() {},
};
</script>

<style lang="scss">
.st-input {
  /*height: 56px;*/
  .form-item-right {
    padding-left: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.vertical {
      padding-left: 8px;

      .el-tag {
        margin-left: 0;
        margin-right: 8px;
      }
    }

    .input-placeholder {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #b7bec8;
      line-height: 56px;

      .input-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    .input-icon {
      font-size: 18px;
      color: #b7bec8;
      line-height: 56px;
    }
  }

  .form-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 21px;
    .upload-box {
      text-align: center;
      .upload-image {
        width: 142px;
        height: 81px;
      }
      .upload-tip {
        margin-top: 11px;
        font-size: 14px;
        color: #b7bec8;
        line-height: 14px;
      }
    }
  }

  .flex-wrap {
    flex-wrap: wrap;
    margin-top: 14px;
    margin-bottom: -8px;
    padding-bottom: 14px;

    .el-tag {
      margin-left: 8px;
      margin-bottom: 8px;
    }
  }
}
</style>
