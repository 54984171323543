<template>
  <div style="padding-bottom: 50px;background-color: #fff" class="">
    <!-- 组件库 -->
    <el-collapse v-model="activeMenu">
      <!-- 基础组件 -->
      <el-collapse-item :name="key"
        v-for="(key) in libraryGroup" :key="key">
        <template slot="title">
          <span class="el-tree-node__content custom-collapse-title">
            <i class="el-icon-caret-right el-tree-node__expand-icon"></i>
            <span class="custom-tree-node">
              {{key === 'base' ? '基础组件' : ''}}
              {{key === 'survey' ? '基础组件' : ''}}
              {{key === 'activity' ? '基础组件' : ''}}
              {{key === 'personal' ? '个人信息' : ''}}
              {{key === 'other' ? '其他信息' : ''}}
              {{key === 'unit' ? '单位信息' : ''}}
              {{key === 'user' ? '个人资料表单' : ''}}
            </span>
          </span>
        </template>
        <!-- 支持从组件库拖到工作区 -->
        <draggable class="library-list" v-model="library[key]" :options="dragOptions" :move="onMove"
          :clone="(original) => handleClone(original, key)">
          <div class="library-item" v-for="(it, i) in library[key]" :key="it.title">
            <div class="box">
              <!-- 未激活图标 -->
              <img class="icon"
                :src="require(`../../../assets/images/custom-form/${it.icon || it.field || 'st-default'}.png`)" />
              <!-- 激活图标 -->
              <img class="icon active"
                :src="require(`../../../assets/images/custom-form/${it.icon || it.field || 'st-default'}__active.png`)" />
              <div class="text">{{it.title}}</div>
              <div class="count" v-if="it.extra">{{it.extra.count}}/{{it.extra.maxCount}}</div>
            </div>
            <div class="no-placement" v-if="it.extra.count >= it.extra.maxCount">
              <span>组件放置已达上限</span>
            </div>
            <div class="placement" v-else-if="it.component === 'st-custom-header'">
              <span>该组件放置区域只能在头部</span>
            </div>
            <div class="placement" v-else-if="it.component === 'st-custom-nav'">
              <span>该组件放置区域只能在最后</span>
            </div>
            <div class="placement" v-else>
              <span>组件放置区域</span>
            </div>
            <div class="no-placement hide">
              <span>组件不可放置在此区域</span>
            </div>
          </div>
        </draggable>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
const rawData = require("../../../assets/jsons/component-library.json");
import draggable from "vuedraggable";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("formPage");
export default {
  name: "PageComponentLibrary",
  components: {
    draggable,
  },
  props: {
    selected: {
      type: [String, Number],
    },
  },
  data() {
    return {
      libraryGroup: [],
      activeMenu: [],
      dragOptions: {
        group: {
          name: "drag",
          pull: "clone",
          put: false,
        },
        sort: false,
      },
    };
  },
  computed: {
    ...mapState(["clone", "library"]),
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
    pageSet() {
      if (this.$route.name === 'ConsultServiceSurveyForm') return 'survey'
      if (this.$route.name === 'ActivityFormDesign') return 'activity'
      return this.$route.params.pageset
    }
  },
  created() {
    let groups = [], library = JSON.parse(JSON.stringify(rawData))
    if (this.pageSet === 'user') {
      groups = ["base", "personal", "other"]
    } else if (this.pageSet === 'unit') {
      groups = ['base', 'unit']
    } else if (this.pageSet === 'survey') {
      groups = ['survey', 'user']
      library = JSON.parse(JSON.stringify(this.library))
    } else if (this.pageSet === 'activity') {
      groups = ['activity', 'user']
      library = JSON.parse(JSON.stringify(this.library))
    }

    let myLib = {}
    groups.forEach(group => {
      if (!library[group]) return
      /* tenantVersion 应支持 字符串 或 字符串数组 */
      myLib[group] = library[group].filter(el =>
        (typeof el.tenantVersion === 'string' && el.tenantVersion === this.tenantVersion)
        || (Array.isArray(el.tenantVersion) && el.tenantVersion.includes(this.tenantVersion))
        || el.tenantVersion === undefined)
      if (myLib[group].length > 0) {
        /* 组件库分组有数据才在界面上显示分组 */
        this.libraryGroup.push(group)
        /* 默认所有分组组件全部展开 */
        this.activeMenu.push(group)
      }
    })
    this.$store.commit(
      "formPage/setLibrary",
      JSON.parse(JSON.stringify(myLib))
    );
  },
  methods: {
    onMove(e) {
      let flag = true;
      // 不允许停靠
      if (e.relatedContext.element?.component === "st-custom-header") {
        flag = false;
      }
      return flag;
    },
    handleClone(original, key) {
      let copy = JSON.parse(JSON.stringify(original));

      if (copy.field === 'base-title') {
        copy.props.normal.label = `base-title-${new Date().getTime()}`
      }

      let extra = {
        // 保存页面配置时会忽略
        extra: {
          ...copy.extra,
          // 不加唯一标识会造成数据混乱
          timestamp: new Date().getTime(),
          /* 标记base/ */
          type: key,
        },
      };

      const cloneData = Object.assign({}, copy, extra)

      // 辅助记录所拖拽组件的使用次数
      this.$store.commit("formPage/setClone", {
        key: key,
        data: cloneData,
      });

      // console.log('copy', copy)
      // console.log('clone', Object.assign({}, copy, extra))
      return cloneData;
    },
  },
};
</script>

<style lang="scss">
.el-collapse {
  .custom-collapse-title {
    width: 100%;
    color: #606266;
    font-weight: normal;
    height: 36px;
    font-size: 12px;
  }

  .el-collapse-item__header {
    border-bottom: none;
    height: 36px;
    font-size: 14px;

    &.is-active {
      .el-tree-node__expand-icon {
        transform: rotate(90deg);
      }
    }
  }

  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }

  .el-collapse-item__arrow {
    display: none;
  }
}

.library-list {
  display: grid;
  grid-template-columns: repeat(auto-fill,  66.5px);
  justify-content: space-around;
  padding: 0 14px;

  .library-item {
    padding: 4px 0;
    cursor: move;
    text-align: center;

    &:hover,
    &.active {
      color: white;

      .box {
        background-color: $primary;
      }

      .icon {
        display: none;

        &.active {
          display: block;
        }
      }

      .text,
      .count {
        color: white;
      }
    }

    .box {
      border-radius: 2px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .icon {
      width: 32px;
      height: 32px;

      &.active {
        display: none;
      }
    }

    .text,
    .count {
      font-size: 12px;
      line-height: 12px;
    }

    .text {
      color: #3a3a3a;
      margin-top: 8px;
    }

    .count {
      color: #999999;
      margin-top: 8px;
    }
  }

  .no-placement,
  .placement {
    display: none;
  }
}

/* 选中样式 */
.chosen {
  opacity: 0;
}

/* 占位样式 */
.ghost {
  .box {
    display: none;
  }

  .no-placement,
  .placement {
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding: 0 9px;
      color: white;
    }
  }

  .no-placement {
    /* 竖向双色斑马条纹 */
    background: linear-gradient(
      to right,
      rgba(255, 83, 80, 0.4) 12.5%,
      rgba(255, 176, 175, 0.2) 0
    );
    background-size: 8px 100%;

    span {
      background: #ff9391;
    }

    &.hide {
      display: none;
    }
  }

  .placement {
    background: linear-gradient(
      to right,
      rgba(53, 118, 255, 0.4) 12.5%,
      rgba(53, 118, 255, 0.2) 0
    );
    background-size: 8px 100%;

    span {
      background: #6f9aff;
    }
  }
}

.login-tip:not(.ignore) + .library-item {
  .no-placement.hide {
    display: flex;
  }

  .placement, .no-placement:not(.hide) {
    display: none !important;
  }
}
</style>
