<template>
  <div class="page-left">
    <div class="left-name">
      {{ mode === 'grid' ? '自定义表单组件' : '表单组件' }}
    </div>
    <page-component-library
      v-show="mode === 'grid'"
      :selected="value"
    ></page-component-library>
    <page-component-select v-show="mode === 'list'" v-model="componentsList">
    </page-component-select>
  </div>
</template>

<script>
import PageComponentLibrary from './PageComponentLibrary'
import PageComponentSelect from './PageComponentSelect.vue'

export default {
  name: 'PageLeft',
  components: { PageComponentLibrary, PageComponentSelect },
  props: {
    // main.selected
    value: {
      type: [Number, String],
    },
    componentsList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    mode() {
      const { params, name } = this.$route
      return params.id === 0 ||
        params.id === '0' ||
        ['ConsultServiceSurveyForm', 'ActivityFormDesign'].includes(name)
        ? 'grid'
        : 'list'
    },
  },
}
</script>

<style lang="scss">
.page-left {
  height: 100%;

  .left-name {
    font-weight: bold;
    line-height: 80px;
    background-color: #f7f7f7;
    position: sticky;
    top: 0;
    z-index: 5;
    height: 80px;
    color: #3a3a3a;
    font-size: 18px;
    padding: 0 24px;
    white-space: nowrap;
  }
}
</style>
