<template>
  <div class="page-middle" style="--main-color: #3a7aff">
    <!-- 自定义页面 -->
    <div class="workspace-middle-box" @click="updateSelected('page')">
      <div class="workspace-middle-page">
        <!-- 头部背景层 -->
        <div
          :class="['drag-item no-hover']"
          :style="{ height: 64 + 'px', position: 'absolute' }"
        >
          <div
            class="header-fixed"
            :style="{ height: '64px', backgroundColor: 'white' }"
          >
            <!-- 返回按钮 -->
            <img
              :class="['default-back-icon']"
              src="../../../assets/images/Back1@2x.png"
            />
            <!-- 状态栏 -->
            <img
              class="status-bar"
              src="../../../assets/images/phone-statusbar1@2x.png"
            />
          </div>
        </div>
        <div class="workspace-middle-page-content">
          <!-- 默认头部导航 -->
          <div :class="['drag-item no-hover']" class="app-nav">
            <st-page-set :config="page.props"></st-page-set>
          </div>
          <!-- 其他组件 -->
          <draggable
            v-model="myList"
            :class="['draggable-box']"
            draggable=".drag-item"
            animation="400"
            ghostClass="ghost"
            :options="listOptions"
            :move="onMove"
            filter=".no-drag"
            @sort="handleSort"
            @add="handleAdd"
            :disabled="!editable"
          >
            <div
              class="drag-item"
              ref="formItem"
              :class="[
                value === i ? 'active' : '',
                ['st-custom-header', 'st-custom-nav'].includes(li.component)
                  ? 'no-drag'
                  : '',
                (li.title &&
                  checkSameTitle(
                    li.props.normal.label || li.props.title,
                    myList,
                    i
                  )) ||
                errorIndex === i
                  ? 'error'
                  : '',
              ]"
              :id="'formItem' + i"
              :data-last="
                li.props.normal && li.props.normal.marginBottom
                  ? li.props.normal.marginBottom > 0
                  : li.field === 'base-title'
                  ? true
                  : i + 1 < myList.length - 1 &&
                    myList[i + 1].field === 'base-title'
                  ? true
                  : false
              "
              :title="
                li.props.showConfig &&
                li.props.showConfig.dependence &&
                li.props.showConfig.dependence.option
                  ? `当【${li.props.showConfig.dependence.label}】的【${li.props.showConfig.dependence.option}】被选中时显示`
                  : ''
              "
              @click.stop="updateSelected(i)"
              v-for="(li, i) in myList"
              :key="li.extra.timestamp"
            >
              <component
                v-if="li.is_show == undefined || li.is_show === 1"
                :is="li.component"
                :config="li.props"
                :type="li.type"
                :formType="li.field"
                :field="li.field"
              >
              </component>
              <div class="drag-item-tool">
                <div
                  class="tools"
                  v-if="!defaultFields.includes(li.field) && editable"
                >
                  <!-- 添加.stop修饰符阻止冒泡 -->
                  <!-- 重复一个 -->
                  <!-- <img @click.stop="duplicate(i)" src="../assets/images/copy.png" />-->
                  <!-- 上移 -->
                  <!-- <img v-if="i > 0" @click.stop="moveUp(i)" src="../assets/images/up.png" />-->
                  <!-- 下移 -->
                  <!-- <img v-if="i < myList.length - 1" @click.stop="moveDown(i)" src="../assets/images/down.png" />-->
                  <!-- 删除 -->
                  <img
                    @click.stop="deletePageItem(i)"
                    src="../../../assets/images/del_gray.png"
                  />
                </div>
                <div
                  class="arrow-label"
                  v-if="li.title"
                  :style="{
                    display:
                      defaultFields.includes(li.field) || !editable
                        ? 'block'
                        : '',
                  }"
                >
                  {{ li.title }}
                  <i
                    :title="`当【${li.props.showConfig.dependence.label}】的【${li.props.showConfig.dependence.option}】被选中时显示`"
                    class="el-icon-link"
                    v-if="
                      li.props.showConfig &&
                      li.props.showConfig.dependence &&
                      li.props.showConfig.dependence.option
                    "
                  ></i>
                </div>
                <!--<div
                  class="arrow-label" style="top: 40px;"
                  v-if="li.props.showConfig && li.props.showConfig.dependence && li.props.showConfig.dependence.option"
                  :style="{
                    display:
                      defaultFields.includes(li.field) || !editable
                        ? 'block'
                        : '',
                  }"
                >
                  当【{{li.props.showConfig.dependence.label}}】的【{{ li.props.showConfig.dependence.option }}】被选中时显示
                </div>-->
              </div>
              <div class="drag-item-tool-left">
                <div
                  class="disable-label"
                  v-if="
                    li.title &&
                    checkSameTitle(
                      li.props.normal.label || li.props.title,
                      myList,
                      i
                    )
                  "
                  :style="{
                    right:
                      168 +
                      (li.props.normal.label || li.props.title).length * 12 +
                      'px',
                  }"
                >
                  不能填写相同的标题：{{
                    li.props.normal.label || li.props.title
                  }}
                </div>
              </div>
            </div>
            <!-- 为了当页面只有一个禁止停靠元素时，使元素能够停靠在其下方 -->
            <div
              class="drag-item no-hover hide"
              style="height: 1px; background-color: transparent"
            ></div>

            <div
              v-if="myList.length"
              v-show="isAgreement"
              :class="[isAgreement ? '' : 'ignore']"
              class="drag-item login-tip no-hover no-drag"
            >
              <div class="check-box"></div>
              <div class="protocol-text">
                我已阅读并同意
                <span
                  >《{{
                    page.props && page.props.agreementConfig
                      ? page.props.agreementConfig.titleConfig.name
                      : ''
                  }}》</span
                >
              </div>
            </div>
          </draggable>
          <!-- 底部按钮 -->
          <div
            class="drag-item bottom-button no-hover"
            :class="[value === 'bottomBtn' ? 'active' : '']"
          >
            <st-bottom-button :config="bottomBtn.props"></st-bottom-button>
          </div>
        </div>
      </div>
      <!-- 右下角撤销、重做 -->
      <div class="workspace-middle-undo-manage">
        <img
          @click.stop="undo"
          :disabled="!undoManager.canUndo"
          src="../../../assets/images/undo.png"
        />
        <img
          @click.stop="redo"
          :disabled="!undoManager.canRedo"
          src="../../../assets/images/redo.png"
        />
        <!-- <img @click="restore" :disabled="!canRedo" /> -->
      </div>
      <!-- 右上角按钮组 -->
      <div class="workspace-middle-buttons">
        <el-button
          :type="value === 'page' ? 'primary' : ''"
          @click.stop="updateSelected('page')"
        >
          <span class="middle-button-content">
            <img
              v-if="value === 'page'"
              src="../../../assets/images/page-setting-white.png"
            />
            <img v-else src="../../../assets/images/page-setting.png" />
            页面设置</span
          >
        </el-button>
        <!--      <el-button v-if="myList.length" :type="value === 'list' ? 'primary' : ''" @click.stop="updateSelected('list')">-->
        <!--        <span class="middle-button-content"><img src="../../../assets/images/settings.png" />组件列表</span>-->
        <!--      </el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState } from 'vuex'

import UndoManager from '../../../../../base/utils/undo-manager'
var undoManager = UndoManager

import StPageSet from '../Preview/StPageSet'
import StInput from '../Preview/StInput'
import StSelect from '../Preview/StSelect'
import StBottomButton from '../Preview/StBottomButton.vue'
import StImage from '../Preview/StImage.vue'
import StImagePicker from '../Preview/StImage.vue'
import StCertification from '@/modules/organization-admin/components/OrgFrame/Preview/StCertification'
import StTitle from '../Preview/StTitle'

export default {
  name: 'PageMiddle',
  components: {
    StTitle,
    StSelect,
    StInput,
    StPageSet,
    draggable,
    StBottomButton,
    StCertification,
    StImage,
    StImagePicker,
  },
  props: {
    // main.selected (v-model)
    value: {
      type: [String, Number],
    },
    // 接口返回的原始数据
    // pageDetail: Object,
    // 自定义页面相关
    page: Object,
    bottomBtn: Object,
    list: Array,
  },
  data() {
    return {
      undoManager: {
        // 撤销重做
        canUndo: false,
        canRedo: false,
      },
      listOptions: {
        group: {
          name: 'drag',
        },
        dragClass: 'chosen',
      },
      defaultFields: [],
      myList: [],

      routeNameList: ['ActivityFormDesign', 'ConsultServiceSurveyForm'],

      errorIndex: -1,
    }
  },
  computed: {
    ...mapState('formPage', ['clone', 'library']),
    pageSet() {
      return this.$route.params.pageset
    },
    editable() {
      return (
        this.$route.params.id == 0 ||
        this.routeNameList.includes(this.$route.name)
      )
    },
    isAgreement() {
      const { $route, page } = this
      const routeNames = ['FormPage', ...this.routeNameList]
      return (
        routeNames.includes($route.name) &&
        page.props &&
        page.props.agreementConfig &&
        page.props.agreementConfig.titleConfig.isShow
      )
    },
  },
  watch: {
    list(newValue) {
      this.myList = newValue
    },
  },
  created() {
    this.myList = this.list
    if (this.pageSet === 'user') {
      this.defaultFields = [
        'avatar',
        'nickname',
        'gender',
        'phone',
        // "position_id",
        'invitation_code',
      ]
    } else if (this.pageSet === 'unit') {
      this.defaultFields = ['avatar', 'nickname', 'phone']
    }
  },
  methods: {
    /* 表单标题查重方法 */
    checkSameTitle(title, list, index) {
      let flag = false
      list.forEach((el, i) => {
        if (
          (el.props.normal.label || el.props.title) === title &&
          index !== i
        ) {
          flag = true
        }
      })
      return flag
    },
    /* 刷新撤销/重做状态 */
    refreshUndoStatus() {
      this.undoManager.canUndo = undoManager.hasUndo()
      this.undoManager.canRedo = undoManager.hasRedo()
    },
    /* 注意两个拖拽容器都要配置才能禁止停靠 */
    onMove(e) {
      let flag = true
      /* 不允许拖拽、停靠（需配合draggable的filter属性 */
      if (e.relatedContext.element?.component === 'st-custom-header') {
        flag = false
      }
      return flag
    },
    /**
     * 监听"页面"拖拽排序操作
     */
    handleSort(ev) {
      if (this.isIgnore) {
        this.isIgnore = false
      } else {
        let realIndex =
          ev.newIndex === this.myList.length ? ev.newIndex - 1 : ev.newIndex
        let target = this.myList[realIndex]
        if (this.hasCustomNav && realIndex === this.myList.length - 1) {
          /* 如果有自定义导航，则不能放在最后一个 */
          this.myList.splice(realIndex, 1)
          /* 改为放到自定义导航前面一个 */
          this.myList.splice(realIndex - 1, 0, target)
          undoManager.add({
            undo: () => {
              this.myList.splice(realIndex - 1, 1)
              this.myList.splice(ev.oldIndex, 0, target)
            },
            redo: () => {
              this.myList.splice(ev.oldIndex, 1)
              this.myList.splice(realIndex - 1, 0, target)
            },
          })
          this.updateSelected(realIndex - 1)
        } else {
          undoManager.add({
            undo: () => {
              this.myList.splice(realIndex, 1)
              this.myList.splice(ev.oldIndex, 0, target)
            },
            redo: () => {
              this.myList.splice(ev.oldIndex, 1)
              this.myList.splice(realIndex, 0, target)
            },
          })
          this.updateSelected(realIndex)
        }
        this.refreshUndoStatus()
        this.$emit('update-list', this.myList)
      }
    },
    /**
     * 从左侧列表添加了一个组件到"页面"
     */
    handleAdd(ev) {
      // console.log('add', ev.newIndex)
      // 添加一个新的子项目时，也会触发sort事件，所以使用此字段忽略后续操作
      this.isIgnore = true
      /**
       * 为了实现列表中仅有一个禁止拖拽、停靠的元素时仍能支持停靠在其下方，
       * 固定了一个"假的"列表元素，
       * 导致此事件返回的下标受影响
       * */
      let realIndex =
        ev.newIndex === this.myList.length ? ev.newIndex - 1 : ev.newIndex

      if (this.myList[realIndex] === undefined) {
        // realIndex 不靠谱哒！通过唯一id识别
        let _realIndex = this.myList.findIndex(
          (li) => this.clone.data.extra.timestamp === li.extra.timestamp
        )

        // console.table(this.myList.map(li => li.field + ':' + JSON.stringify(li.extra)))

        if (_realIndex !== -1) {
          realIndex = _realIndex

          if (this.isAgreement) {
            this.myList.splice(_realIndex, 1) /* 位置不合适，不能添加 */
            return
          }
        }
      }

      if (this.clone.data.extra.count >= this.clone.data.extra.maxCount) {
        /* 超过上限，不能添加 */
        this.myList.splice(realIndex, 1)
        return
      }
      if (this.myList[realIndex].component === 'st-custom-header') {
        // 某些组件只能放在数组第一项的位置
        let first = this.myList.splice(realIndex, 1)[0]
        this.myList.unshift(first)
        undoManager.add({
          undo: () => {
            this.myList.shift()
          },
          redo: () => {
            this.myList.unshift(first)
          },
        })
        this.updateSelected(0)
        /* 组件使用次数+1 */
        this.updateCount(1)
      } else if (this.myList[realIndex].component === 'st-custom-nav') {
        // 某些组件只能放在数组最后的位置
        let target = this.myList.splice(realIndex, 1)[0]
        this.myList.push(target)
        undoManager.add({
          undo: () => {
            this.myList.pop()
          },
          redo: () => {
            this.myList.push(target)
          },
        })
        this.updateSelected(this.myList.length - 1)
        this.updateCount(1)
      } else {
        // 放置普通组件
        let target = this.myList[realIndex]
        if (this.hasCustomNav && realIndex === this.myList.length - 1) {
          // 不能放在自定义导航后面
          this.myList.splice(realIndex, 1)
          /* 改为放在自定义导航的前面 */
          this.myList.splice(realIndex - 1, 0, target)
          undoManager.add({
            undo: () => {
              this.myList.splice(realIndex - 1, 1)
            },
            redo: () => {
              this.myList.splice(realIndex - 1, 0, target)
            },
          })
          this.updateSelected(realIndex - 1)
          this.updateCount(1)
        } else {
          /* 普通放置即可 */
          undoManager.add({
            undo: () => {
              this.myList.splice(realIndex, 1)
            },
            redo: () => {
              this.myList.splice(realIndex, 0, target)
            },
          })
          this.updateSelected(realIndex)
          this.updateCount(1)
        }
      }
      this.refreshUndoStatus()
      this.$emit('update-list', this.myList)
    },
    /* 删除 */
    deletePageItem(i) {
      if (typeof this.value === 'number' && this.value !== -1) {
        /* 删除后要修改selected */
        if (i > this.value) {
          /* 不用管 */
        } else if (i === this.value) {
          /* 如果后面还有元素，选中那个元素，否则-1 */
          if (!this.myList[i + 1]) {
            this.updateSelected('page')
          }
        } else if (i < this.value) {
          this.updateSelected(this.value - 1)
        }
      }
      let target = this.myList.splice(i, 1)[0]
      undoManager.add({
        undo: () => {
          this.myList.splice(i, 0, target)
        },
        redo: () => {
          this.myList.splice(i, 1)
        },
      })
      this.refreshUndoStatus()
      /* 组件计数减1 */
      this.updateCount(0, target)
      this.$emit('update-list', this.myList)
    },
    /* 修改组件使用次数 */
    updateCount(plus, target) {
      /* 组件使用次数+1 */
      let newLibrary = JSON.parse(JSON.stringify(this.library))

      let updated = false

      if (plus) {
        if (!target) {
          /* 增加 */
          newLibrary[this.clone.key].forEach((li) => {
            if (updated) return

            if (!this.clone.data) return

            if (this.clone.data.is_user_info !== 1) {
              if (li.field === this.clone.data.field) {
                li.extra && li.extra.count++
                updated = true
              }
            } else {
              if (li.field === this.clone.data.field) {
                // 在个人资料表单组件库中，field 是可能重复的，因为基础组件可以存在多个
                if (li.title === this.clone.data.title) {
                  // 必须同名
                  li.extra && li.extra.count++
                  updated = true
                }
              }
            }
          })
        } else {
          // survey 和 user 都有相同的 field: phone，只要 survey 的
          ;['activity', 'survey', 'base', 'user', 'personal', 'other'].forEach(
            (group) => {
              if (newLibrary[group])
                newLibrary[group].forEach((li) => {
                  if (updated) return

                  if (li.field === target.field) {
                    if (target.is_user_info) {
                      if (li.title === li.props.normal.label) {
                        li.extra && li.extra.count++
                        updated = true
                      }
                    } else {
                      li.extra && li.extra.count++
                      updated = true
                    }
                  }
                })
            }
          )
        }
      } else {
        /* 减少 */
        if (target.extra && target.extra.type && target.extra.type !== 'user') {
          newLibrary[target.extra.type].forEach((li) => {
            if (updated) return

            if (
              li.field === target.field &&
              li.is_user_info === target.is_user_info
            ) {
              li.extra && li.extra.count--
              updated = true
            }
          })
        } else {
          /* 编辑页面 */
          // survey 和 user 都有相同的 field: phone，只要 survey 的

          ;['activity', 'survey', 'base', 'user', 'personal', 'other'].forEach(
            (group) => {
              if (newLibrary[group])
                newLibrary[group].forEach((li) => {
                  if (updated) return

                  if (li.field === target.field) {
                    if (target.is_user_info) {
                      // is_user_info 必须一致
                      if (!li.is_user_info) return

                      if (li.title === target.title) {
                        li.extra && li.extra.count--
                        updated = true
                      }
                    } else {
                      // is_user_info 必须一致
                      if (li.is_user_info) return

                      li.extra && li.extra.count--
                      updated = true
                    }
                  }
                })
            }
          )
        }
      }

      this.$store.commit('formPage/setLibrary', newLibrary)
    },
    updateSelected(key) {
      this.$emit('input', key)
      this.errorIndex = -1
      if (this._timer) {
        clearInterval(this._timer)
        this._timer = null
      }
    },
    undo() {
      this.updateSelected('page')
      if (this.undoManager.canUndo) {
        undoManager.undo()
        this.refreshUndoStatus()
      } else {
        this.$message.info('不能再撤回了')
      }
    },
    redo() {
      this.updateSelected('page')
      if (this.undoManager.canRedo) {
        undoManager.redo()
        this.refreshUndoStatus()
      } else {
        this.$message.info('不能再恢复了')
      }
    },
    /* 上移 */
    moveUp(i) {
      let target = this.myList.splice(i, 1)[0]
      this.myList.splice(i - 1, 0, target)
      undoManager.add({
        undo: () => {
          let target = this.myList.splice(i, 1)[0]
          this.myList.splice(i - 1, 0, target)
        },
        redo: () => {
          let target = this.myList.splice(i, 1)[0]
          this.myList.splice(i - 1, 0, target)
        },
      })
      this.refreshUndoStatus()
      this.$emit('update-list', this.myList)
    },
    /* 下移 */
    moveDown(i) {
      let target = this.myList.splice(i, 1)[0]
      this.myList.splice(i + 1, 0, target)
      undoManager.add({
        undo: () => {
          let target = this.myList.splice(i, 1)[0]
          this.myList.splice(i + 1, 0, target)
        },
        redo: () => {
          let target = this.myList.splice(i, 1)[0]
          this.myList.splice(i + 1, 0, target)
        },
      })
      this.refreshUndoStatus()
      this.$emit('update-list', this.myList)
    },
    // 表单配置校验错误提示及定位
    errFormConfig(index) {
      this.errorIndex = index

      this.$nextTick(() => {
        let elem = document.getElementById('formItem' + index)
        elem.scrollIntoView({
          behavior: 'smooth', // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
          block: 'center', // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
          inline: 'nearest', // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
        })

        let counter = 5 // 闪烁红色轮廓

        this._timer = setInterval(() => {
          if (counter < 0) {
            if (this._timer) clearInterval(this._timer)
            if (this.errorIndex > -1) this.errorIndex = -1
            return
          }
          this.errorIndex = this.errorIndex === -1 ? index : -1
          counter--
        }, 600)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-middle {
  position: relative;
  height: 100%;
  ::v-deep.el-image__error {
    display: none;
  }
}

.workspace-middle-box {
  padding: 63px 0 80px;
  overflow-y: auto;
  height: 100%;

  .workspace-middle-page {
    box-shadow: 0 4px 8px 0 #d5deeb;
    margin: 0 auto;
    width: 375px;
    min-height: 667px;
    position: relative;
    background-color: #f4f5f6;

    &.no-hover {
      &::before {
        display: none;
      }

      .drag-item {
        &:hover {
          outline: none;
        }

        &.active {
          outline: none;
        }
      }
    }
  }

  .bottomWatermark {
    position: absolute;
    bottom: 100px;
  }

  .workspace-middle-page-content {
    position: relative;
    min-height: 667px;

    &::before {
      content: 'iPhone 8 手机高度';
      font-size: 12px;
      line-height: 17px;
      padding: 3px 12px 3px 0;
      color: #3a3a3a;
      position: absolute;
      top: 643px;
      border-bottom: 1px solid #606266;
      transform: translateX(-100%);
    }
  }
}

/* 固定按钮：撤销、重做 */
.workspace-middle-undo-manage {
  position: absolute;
  right: 32px;
  bottom: 10%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 25px;
  z-index: 3;
  height: 42px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #d5deeb;
  border-radius: 21px;
  padding: 0 6px;

  img {
    width: 28px;
    margin: 0 6px;

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }

    &[disabled] {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
}

/* 固定按钮 */
.workspace-middle-buttons {
  position: absolute;
  top: 63px;
  right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;

  .el-button {
    margin-left: 0;
    margin-top: 12px;
    font-size: 12px;
    line-height: 12px;
    padding: 8px 10px;
    border: none;
    box-shadow: 0 2px 4px 0 #d5deeb;
  }

  .middle-button-content {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 4px;
      width: 16px;
    }
  }
}

/* 使组件放置区域变大 */
.draggable-box {
  padding: 20px 0;
  min-height: 533px;

  /**
  &:before {
    content: "放置区域";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $placeholder;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 20px 80px;
    border: dashed 1px $placeholder;
    white-space: nowrap;
  } */

  &.custom {
    min-height: 617px;
  }
}

.drag-item-tool {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -42px;
  top: 0;

  .tools {
    display: none;
    /*position: absolute;*/
    /*width: 40px;*/
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 0 2px 2px 0;
    cursor: default;

    img {
      cursor: pointer;
      font-size: 18px;
      margin: 12px 0;
      width: 20px;
    }
  }

  .arrow-label {
    display: block;
    position: absolute;
    right: 28px;
    transform: translateX(100%);
    top: 0;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 2px;
    font-size: 12px;
    color: #3a3a3a;
    line-height: 12px;
    padding: 8px 15px;
    white-space: nowrap;
    pointer-events: none;

    &::before {
      content: '\00a0';
      width: 0;
      height: 0;
      display: block;
      border-style: solid;
      border-width: 7px 16px 7px 0;
      border-color: transparent white transparent transparent;
      position: absolute;
      left: -15%;
    }
  }
}

.drag-item-tool-left {
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  left: -42px;
  top: 0;

  .disable-label {
    display: block;
    position: absolute;
    transform: translateX(100%);
    top: 10px;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 2px;
    font-size: 12px;
    color: #f56c6c;
    line-height: 12px;
    padding: 8px 15px;
    white-space: nowrap;
    pointer-events: none;

    &::after {
      content: '\00a0';
      width: 0;
      height: 0;
      display: block;
      border-style: solid;
      border-width: 7px 0 7px 16px;
      border-color: transparent white transparent;
      position: absolute;
      right: -8%;
      top: 7px;
    }
  }
}

.header-fixed {
  width: 375px;
  position: absolute;
  z-index: 0;
  top: 0;

  .page-bg {
    position: absolute;
    bottom: 0;
    left: 50%;
    overflow: hidden;
    .el-image {
      width: 375px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -187px;
    }
  }

  .default-back-icon {
    position: absolute;
    top: 33px;
    left: 11px;
    width: 20px;
    height: 20px;
  }

  .template-back-icon {
    position: absolute;
    top: 26px;
    left: 5px;
    width: 78px;
    height: 32px;
  }

  .link-dialog1 {
    width: 129px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #d3d3d3;
    padding: 0 11px;

    .link-item1 {
      padding: 13px 0;
      display: flex;
      align-items: center;
      .link-image1 {
        width: 20px;
        height: 20px;
        margin-right: 9px;
      }
      .link-name1 {
        font-size: 14px;
        color: #111111;
      }
    }

    .link-item1 + .link-item1 {
      border-top: 1px solid #d3d3d3;
    }
  }

  .link-dialog2 {
    width: 283px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 13px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #d3d3d3;

    .link-item2 {
      text-align: center;
      flex: 0 0 25%;
      margin-bottom: 13px;
      .link-image2 {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
      }
      .link-name2 {
        font-size: 14px;
        line-height: 14px;
        color: #111111;
      }
    }
  }

  .back-icon {
    width: 100%;
    height: 100%;
  }

  .el-image {
    width: 100%;
    height: 100%;
  }
}

.status-bar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 20px;
  z-index: 2;
}

.drag-item {
  width: 100%;
  position: relative;
  outline: none;

  &.fixed {
    position: absolute;
    bottom: 0;
    height: 50px;
  }

  &:hover {
    outline: 2px dashed #3479ef;
    outline-offset: -2px;
    cursor: move;

    .tools {
      display: flex;
    }

    .arrow-label {
      display: none;
    }
  }

  &.error {
    outline: 2px solid #f56c6c !important;
    outline-offset: -2px;
  }

  &.active {
    outline: 2px solid #3479ef;
    outline-offset: -2px;
  }

  &.no-hover {
    outline: none;
    cursor: default;
  }

  .resize {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: s-resize;
  }

  & + .drag-item {
    &.hide {
      margin-top: 0;
    }
  }
}
/* 针对表单分组样式 */
.drag-item {
  & + .drag-item {
    .st-form-item,
    ::v-deep.st-form-item {
      .base-form-item-inner {
        border-top: 1px solid #f1f1f1;
      }
    }
  }
}

$radius: 4px;

.drag-item[data-last='true'] {
  .st-form-item,
  ::v-deep.st-form-item {
    .base-form-item-box {
      border-bottom-right-radius: $radius !important;
      border-bottom-left-radius: $radius !important;
    }
  }

  & + .drag-item {
    .st-form-item,
    ::v-deep.st-form-item {
      .base-form-item-box {
        border-top-right-radius: $radius;
        border-top-left-radius: $radius;
      }

      .base-form-item-inner {
        border-top: none;
      }
    }
  }
}

.draggable-box {
  .drag-item {
    &:first-child {
      .st-form-item,
      ::v-deep.st-form-item {
        .base-form-item-box {
          border-radius: $radius $radius 0 0;
        }
      }
    }
  }

  .drag-item:nth-last-child(3) {
    .st-form-item,
    ::v-deep.st-form-item {
      .base-form-item-box {
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
      }
    }
  }
}

/* Copy 登录页系统页 */
.login-tip {
  margin-top: 20px;
  display: flex;
  padding: 0 18px;

  .check-box {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #efefef;
  }
  .protocol-text {
    font-size: 12px;
    display: flex;
    align-items: center;
    width: 270px;
    flex-wrap: wrap;

    span {
      color: var(--main-color);
    }
  }
}
</style>
