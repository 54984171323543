<template>
  <div class="st-form-editor" v-if="rulesArr">
    <div
      class="flex-col"
      v-if="editable && myValue && JSON.stringify(myValue) !== '{}'"
    >
      <div
        class="editor-item"
        v-for="key in Object.keys(myValue)"
        :key="type + key"
        :style="{
          order: keyDict.indexOf(key) > -1 ? keyDict.indexOf(key) : 99,
        }"
      >
        <template v-if="rulesArr && rulesArr[key]">
          <div
            v-show="
              (rulesArr[key].isShow === undefined ||
                rulesArr[key].isShow === 1) &&
              (rulesArr[key].tenantVersion === undefined ||
                rulesArr[key].tenantVersion === tenantVersion)
            "
          >
            <p
              class="form-title"
              v-if="rulesArr[key].title && rulesArr[key].isShowTitle !== false"
            >
              <span>{{ rulesArr[key].title }}</span>
            </p>
            <form-create
              :rule="formItemRules(rulesArr[key].rules)"
              v-model="fApi"
              :option="options"
              :value.sync="myValue[key]"
            >
            </form-create>

            <div
              v-if="
                !rulesArr[key] ||
                (formItemRules(rulesArr[key].rules) &&
                  !formItemRules(rulesArr[key].rules).length)
              "
              class="st-form-editor-disable"
              style="height: 250px"
            >
              <div class="disable-tip">
                <el-image
                  class="tip-icon"
                  :src="require('../../../assets/images/disable-edit-icon.png')"
                />
                <p class="tip-text">
                  {{
                    editable
                      ? `如需修改页面标题、按钮文本，\n请 `
                      : '当前页面无法设置组件内容'
                  }}<span
                    @click="handleAddForm"
                    class="to-app-design"
                    v-if="editable"
                    >前往页面装修</span
                  >
                </p>

                <el-button
                  v-if="!editable"
                  size="mini"
                  style="width: 222px; margin-top: 32px"
                  type="primary"
                  plain
                  @click="handleAddForm"
                  >前往设置组件内容
                </el-button>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="st-form-editor-disable" v-if="Object.keys(myValue).filter(el => rulesArr && rulesArr[el]).length === 0">
        <div class="disable-tip">
          <el-image
              class="tip-icon"
              :src="require('../../../assets/images/disable-edit-icon.png')"
          />
          <p class="tip-text">无法设置组件内容</p>
        </div>
      </div>
    </div>
    <!--    <div v-else-if="isUserInfo === 1" class="st-form-editor-disable">-->
    <!--      &lt;!&ndash; 需要去入会表单修改，这里只是读取 &ndash;&gt;-->
    <!--      <div class="disable-tip">-->
    <!--        <el-image-->
    <!--          class="tip-icon"-->
    <!--          :src="require('../../../assets/images/disable-edit-icon.png')"-->
    <!--        />-->
    <!--        <p class="tip-text">无法设置组件内容</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <div
      v-else-if="routeNameList.includes($route.name)"
      class="st-form-editor-disable"
    >
      <!-- 页面设置 -->
      <div class="disable-tip">
        <el-image
          class="tip-icon"
          :src="require('../../../assets/images/disable-edit-icon.png')"
        />
        <p class="tip-text">暂无设置</p>
      </div>
    </div>
    <div v-else class="st-form-editor-disable">
      <div class="disable-tip">
        <el-image
          class="tip-icon"
          :src="require('../../../assets/images/disable-edit-icon.png')"
        />
        <p class="tip-text">
          {{
            $route.params.id == 0
              ? '如需修改页面设置，请'
              : '当前页面无法设置组件内容'
          }}<span
            @click="handleAddForm"
            class="to-app-design"
            v-if="$route.params.id == 0"
            >前往页面装修</span
          >
        </p>
        <el-button
          v-if="$route.params.id != 0"
          size="mini"
          style="width: 222px; margin-top: 32px"
          type="primary"
          plain
          @click="handleAddForm"
          >前往设置组件内容
        </el-button>
      </div>
    </div>
    <div
      class="jump-btn"
      v-if="rulesArr && rulesArr.pageJump && rulesArr.pageJump.isShow"
    >
      <el-button size="mini" plain @click="handleJumpPage"
        >{{ rulesArr.pageJump.title }}
      </el-button>
    </div>
  </div>
</template>

<script>
import update from '../../../assets/js/update'

import SingleMediaWall from '../../../../common/components/SingleMediaWall'
import MediaWall from '../../../../common/components/MediaWall'
import StNumberSlider from '../Form/StNumberSlider'
import StColorPicker from '../Form/StColorPicker'
import StRadioButtonGroup from '../Form/StRadioButtonGroup'
import StTemplateRadio from '../Form/StTemplateRadio'
import StDragImageSelector from '../Form/StDragImageSelector'
import StIsShowTitle from '../Form/StIsShowTitle'
import StCheckBoxGroup from '../Form/StCheckBoxGroup'
import StUnCheckableBox from '../Form/StUnCheckableBox'
import StDragIdentity from '../Form/StDragIdentity'
import StDragCertificates from '../Form/StDragCertificates'
import StCityData from '../Form/StCityData'
import StDragOptionList from '../Form/StDragOptionList'

import StFontStyleForm from '../Form/StFontStyleForm'
import StSpecialInput from '../../../../app-design/components/Form/StSpecialInput'
import StNativeInput from '../../../../app-design/components/Form/StNativeInput'

import RestrictSelector from '../Form/RestrictSelector'

import formCreate from '@form-create/element-ui'

formCreate.component(RestrictSelector)
formCreate.component(MediaWall)
formCreate.component(SingleMediaWall)
formCreate.component(StNumberSlider)
formCreate.component(StColorPicker)
formCreate.component(StRadioButtonGroup)
formCreate.component(StTemplateRadio)
formCreate.component(StDragImageSelector)
formCreate.component(StIsShowTitle)
formCreate.component(StCheckBoxGroup)
formCreate.component(StUnCheckableBox)
formCreate.component(StDragIdentity)
formCreate.component(StDragCertificates)
formCreate.component(StDragOptionList)
formCreate.component(StCityData)
formCreate.component(StFontStyleForm)
formCreate.component(StSpecialInput)
formCreate.component(StNativeInput)

export default {
  name: 'StFormEditor',
  components: {
    StDragOptionList,
    formCreate: formCreate.$form(),
  },
  model: {
    prop: 'value',
    event: 'updateValue',
  },
  props: {
    value: {
      type: [Object, Array],
      required: true,
    },
    // example: "st-page-set"
    component: {
      type: String,
    },
    type: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    isUserInfo: [Boolean, Number], // default: undefined
  },
  data() {
    return {
      rulesArr: null,
      fApi: {},
      options: {
        // 整体表单样式规则
        form: {
          size: 'small',
          labelWidth: '82px',
          labelPosition: 'left',
        },
        submitBtn: {
          show: false,
        },
        mounted: (fApi) => {
          fApi.rule.forEach((item, i) => {
            if (item.update) {
              const updateKey = item.update
              try {
                if (typeof updateKey !== 'function') {
                  item.update = update[this.field][updateKey][item.field](this)
                }
                item.update(item.value, item, fApi, true) // 第四个参数，表示初始化立即触发，其他就是自身的值或者关联的值改变时才触发
              } catch (e) {
                throw new Error(
                  'update.js 缺少 ' + this.field + '.' + updateKey + ' 声明'
                )
              }
            }
          })
        },
        onSubmit: (formData) => {
          console.log(formData)
        },
      },
      // order数值越小，越靠前
      keyDict: [
        'page_config',
        'templateData',
        'normal',
        'header',
        'searchConfig',
        'share',
        'showConfig',
        'info',
      ],
      routeNameList: ['ActivityFormDesign', 'ConsultServiceSurveyForm'],
    }
  },
  computed: {
    myValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('updateValue', val)
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
    editable() {
      return (
        this.$route.params.id == 0 ||
        this.routeNameList.includes(this.$route.name)
      )
    },
  },
  watch: {
    value: {
      handler(val, oldVal) {
        if (this.type === 'st-custom-header') {
          // console.log(val.normal)
        }
      },
      deep: true,
    },
  },
  created() {
    // if (this.type === "st-custom-header") {
    //   console.log("StFormEditor created");
    // }
    const isSpecial = [
      'st-page-set',
      'st-bottom-button',
      'st-certification',
      'st-image',
      'st-image-picker',
    ].includes(this.component)
    const rulesArr =
      this.tenantVersion === 'school'
        ? require('../../../assets/jsons/form-create-school.json')
        : require('../../../assets/jsons/form-create.json')
    const r = rulesArr[this.component]

    if (isSpecial) {
      if (this.routeNameList.includes(this.$route.name)) {
        const rulesArrLocal = require('@/modules/activity/data/form-create.json')
        if (rulesArrLocal[this.component])
          this.handleRules(rulesArrLocal[this.component])
        else if (r) {
          this.handleRules(rulesArr[this.component])
        } else {
          this.rulesArr = {}
        }
      } else if (r) {
        this.handleRules(rulesArr[this.component])
      } else {
        this.rulesArr = {}
      }
    } else if (
      this.field === 'base-file-picker' ||
      ['base-file-picker', 'base-multi-select'].includes(this.field)
    ) {
      this.handleRules(rulesArr[this.field])
    } else {
      if (r) {
        if (r[this.type]) {
          if (r[this.type][this.field]) {
            this.handleRules(r[this.type][this.field])
          } else {
            this.rulesArr = {}
          }
        } else if (r.normal) {
          this.handleRules(r)
        } else {
          this.rulesArr = {}
        }
      } else {
        this.rulesArr = {}
      }
    }
  },
  destroyed() {
    // if (this.type === "st-custom-header") {
    //   console.log("StFormEditor destroyed");
    //   // this.fApi.destroy()
    // }
  },
  methods: {
    /**
     * 设置 rulesArr 的值
     * 动态加载"显示约束"配置
     */
    handleRules(rulesArr) {
      const _rulesArr = JSON.parse(JSON.stringify(rulesArr))
      if (['ActivityFormDesign'].includes(this.$route.name)) {
        if (_rulesArr.showConfig !== undefined) _rulesArr.showConfig.isShow = 1
        else if (
          [
            'base-input',
            'base-title',
            'base-select',
            'base-multi-select',
            'base-file-picker',
            'base-image-picker',
          ].includes(this.field)
        ) {
          _rulesArr.showConfig = JSON.parse(
            JSON.stringify(
              require('@/modules/organization-admin/assets/jsons/common-form-create')
                .showConfig
            )
          )
        } // 必须深拷贝
      }
      this.rulesArr = _rulesArr
    },
    formItemRules(rules = []) {
      const { $route, isUserInfo } = this
      return (
        rules
          .filter(
            (f) =>
              (this.routeNameList.includes($route.name) &&
                !f.field.startsWith('privacy_')) ||
              !this.routeNameList.includes($route.name)
          ) // 隐藏隐私设置
          // .filter(f =>
          //   (this.routeNameList.includes($route.name) &&
          //       f.field !== 'required') ||
          //   !this.routeNameList.includes($route.name)
          // ) // 必填项
          .map((r) => {
            if (r.field === 'label' && isUserInfo) {
              if (r.props) {
                r.props.disabled = true
              } else {
                r.props = { disabled: true }
              }
            }
            // if (isUserInfo) {
            //   if (r.field === 'options') {
            //     if (r.props) {
            //       r.props.disabled = true
            //     } else {
            //       r.props = { disabled: true }
            //     }
            //   }
            //
            //   if (r.field === 'required') {
            //     if (r.props) {
            //       r.props.disabled = true
            //     } else {
            //       r.props = { disabled: true }
            //     }
            //   }
            // }
            return r
          })
      ) // 禁用表单项标题修改
    },
    // 前往设置组件内容
    handleAddForm() {
      // let paramsData = this.$store.getters["formPage/paramsData"];
      let routerData = this.$router.resolve({
        name: this.$route.params.id != 0 ? 'FormPage' : 'AppDesignIndex',
        params: { id: 0 },
      })
      window.open(routerData.href, '_blank')
    },
    // 表单底部页面跳转
    handleJumpPage() {
      let routerData = this.$router.resolve({
        name: this.rulesArr.pageJump.routeName,
      })
      window.open(routerData.href, '_blank')
    },
  },
}
</script>

<style lang="scss">
.st-form-editor {
  /* 重置el-button按钮样式 */
  .el-button.is-plain {
    color: $st-blue;
    border-color: $st-blue;
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    /*background-color: white;*/
  }

  .el-slider__runway {
    border-radius: 2px;
  }

  .el-slider__runway,
  .el-slider__bar {
    height: 4px;
  }

  .el-slider__runway.show-input {
    width: calc(100% - 146px);
    margin-right: 146px;
  }

  .el-slider__button-wrapper {
    top: -16px;
  }

  .el-slider__button {
    border-color: white;
    box-shadow: 0 0 8px 0 rgba(100, 101, 102, 0.12);
  }

  .el-checkbox__input.is-disabled {
    .el-checkbox__inner {
      background-color: #c0c4cc;
      border-color: #c0c4cc;

      &::after {
        border-color: white;
      }
    }
  }

  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 24px;
  }

  .el-form-item--small .el-form-item__label {
    line-height: 30px;
  }

  .el-form-item__content {
    & > .el-cascader,
    & > .el-input,
    & > .el-textarea,
    & > .el-select {
      width: 235px;
      float: right;
      max-width: 100%;
    }

    & > .el-checkbox {
      float: right;
    }
  }

  .el-input--small {
    line-height: 30px;

    .el-input__inner {
      height: 30px;
    }
  }

  .el-radio-button--small .el-radio-button__inner {
    padding: 8px 13px;
    min-width: 48px;
  }

  .el-input-number--small {
    line-height: 28px;
  }

  .el-color-picker--small {
    height: 30px;

    .el-color-picker__trigger {
      border-color: #dcdfe6;
      border-radius: 2px;
      padding: 2px;
    }

    .el-color-picker__color {
      border-color: #dcdfe6;
      border-radius: 0;
    }
  }
}

// 组件配置不可编辑缺省页样式
.st-form-editor-disable {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;

  .to-app-design {
    cursor: pointer;
    color: #3479ef;
    margin-left: 4px;
  }

  .disable-tip {
    text-align: center;
    .tip-icon {
      width: 210px;
      height: 100px;
    }
    .tip-text {
      margin-top: 20px;
      font-size: 14px;
      color: #999999;
      line-height: 1.5;
      white-space: pre-wrap;
    }
  }
}
// 表单标题样式
.form-title {
  font-weight: bold;
  font-size: 16px;
  color: #3a3a3a;
  line-height: 16px;
  padding: 20px 0 24px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
}

// 图片提示行内显示样式
.st-inline-img-info {
  color: #c0c4cc;
  position: absolute;
  bottom: 0;
  left: 70px;
  font-size: 12px;
  line-height: 17px;
}

// 单图上传组件提示样式
.st-single-img-info {
  margin-top: 10px;
}

.jump-btn {
  /*position: sticky;*/
  bottom: 0;
  z-index: 2;
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  .el-button {
    width: 100%;
  }
}

// 组件说明内容样式
.explain-content {
  font-size: 14px;
  color: #3a3a3a;
  margin-bottom: 20px;
}

.st-img-info {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
}
</style>
