<!--
 * @Author: dongjia
 * @Date: 2021-08-25 14:18:57
 * @LastEditTime: 2022-01-21 16:30:18
 * @LastEditors: aleaner
 * @Description: 预览二维码弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\organization-admin\components\OrgFrame\Page\PreviewCodeDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="预览" :visible.sync="openDialog" width="480px" :show-close="true" append-to-body
    class="dialog-vertical">
    <div class="dialog-content">
      <div class="auth-content">
        <div class="code-box">
          <el-image class="scan-code" :src="data.h5_qrcode" fit="contain">
            <div style="width: 100%;height: 100%" slot="placeholder" v-loading="true"></div>
          </el-image>
          <p class="code-tip">H5 微网站</p>
        </div>
        <div class="code-box">
          <el-image class="scan-code" :src="data.mp_qrcode" fit="contain">
            <div style="width: 100%;height: 100%" slot="placeholder" v-loading="true"></div>
          </el-image>
          <p class="code-tip">微信小程序</p>
        </div>
        <!-- <p class="content-tip">{{CodeErr?'二维码失效，点击刷新':'请打开微信扫一扫进行预览体验'}}</p> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.auth-content {
  display: flex;
  align-items: center;

  .code-box + .code-box {
    margin-left: 65px;
  }

  .code-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .scan-code {
      width: 160px;
      height: 160px;
    }

    .code-tip {
      margin-top: 13px;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
    }
  }
}
</style>
