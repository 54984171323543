<template>
  <div
    class="st-select st-form-item"
    :style="{
      marginBottom: myConfig.normal.marginBottom + 'px',
    }"
  >
    <base-form-item
      :label="myConfig.normal.label"
      :required="myConfig.normal.required"
      :description="myConfig.normal.description"
    >
      <div
        v-if="['base-file-picker'].includes(field) || field === 'attachment'"
        class="content flex-align-center"
        style="justify-content: flex-end"
      >
        <i class="el-icon el-icon-circle-plus-outline" style="color: #b3b3b3" />
      </div>

      <div v-else
        class="form-item-right"
        :class="{ vertical: myConfig.normal.inputType === 'textarea' }"
      >
        <div v-if="formType !== ''" class="input-placeholder">
          {{ myConfig.normal.value }}
        </div>
        <div v-else class="input-placeholder">
          <el-image
            class="input-avatar"
            :src="defaultAvatar"
            fit="cover"
          ></el-image>
        </div>

        <div
          v-if="type === 'select'"
          class="el-icon-arrow-right input-icon"
        ></div>
      </div>
    </base-form-item>
  </div>
</template>

<script>
import BaseFormItem from './BaseFormItem'
export default {
  name: 'StSelect',
  components: { BaseFormItem },
  props: {
    config: {
      type: Object,
      default: function () {
        return {}
      },
    },
    field: String,
    type: String,
    formType: String
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginBottom: 0,
          required: 1,
          label: '标题内容',
          value: '请选择',
          validate: '',
          option: [''],
          description:"",
          inputType: 'text'
        },
      },
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
}
</script>

<style lang="scss" scoped>
.st-select {
  .content {
    height: 100%;
    padding-right: 4px;

    .el-icon {
      font-size: 18px;
    }
  }

  .form-item-right {
    padding-left: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.vertical {
      padding-left: 8px;
    }

    .input-placeholder {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #b7bec8;
      line-height: 56px;
      .input-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    .input-icon {
      font-size: 18px;
      color: #b7bec8;
      line-height: 56px;
    }
  }
}
</style>
