<template>
  <div class="drag-list">
    <div class="drag-title">选项内容</div>
    <draggable v-if="navList" v-model="navList" class="nav-list" :disabled="true" animation="500"
      draggable=".nav-item">
      <div class="nav-item" v-for="(nav, index) in navList" :key="index">
        <div class="item-title">
          <p class="title-text">{{nav.title}}</p>
          <div class="show-config">
            <p class="show-text cursor-pointer" @click="nav.is_open = nav.is_open ? 0 : 1">{{nav.is_open?'开启':'关闭'}}</p>
            <el-checkbox v-model="nav.is_open" :true-label="1" :false-label="0" />
          </div>
        </div>
        <div v-if="nav.is_open">
          <div class="item-content" v-for="(form,i) in nav.form_data" :key="i">
            <div class="nav-content" v-if="form.field === 'id_card'">
              <p class="nav-title">证件号码</p>
              <el-radio-group v-model="form.props.normal.required" class="radio-group" size="small">
                <el-radio-button v-for="(option, index) in isMustOptions" :key="option.value"
                  :label="option.value">
                  {{option.label}}</el-radio-button>
              </el-radio-group>
            </div>
            <div class="nav-content" v-if="form.field === 'id_card_image'">
              <p class="nav-title">证件照上传</p>
              <el-radio-group v-model="form.props.normal.required" class="radio-group" size="small">
                <el-radio-button v-for="(option, index) in isMustOptions" :key="option.value"
                  :label="option.value">
                  {{option.label}}</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "StDragCertificates",
  components: {
    draggable,
  },
  model: {
    prop: "list",
    event: "listChange",
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentIndex: -1,
      isMustOptions: [
        { value: 0, label: "非必填" },
        { value: 1, label: "必填" },
      ],
    };
  },
  watch: {
    navList: {
      handler(val) {
        this.$emit("listChange", val);
      },
      deep: true,
    },
  },
  computed: {
    navList: {
      get() {
        return this.list;
      },
      set(val) {
        this.$emit("listChange", val);
      },
    },
  },
};
</script>

<style lang='scss' scoped>
.drag-title {
  height: 50px;
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
}
.list-title {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
}
.nav-list {
  background-color: #f6f9ff;
  margin: -20px -24px 0;
  padding: 16px 12px;
}
.nav-item {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;

  .item-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 22px;
    border-bottom: 1px #eeeeee solid;

    .title-text {
      flex-shrink: 0;
      font-size: 16px;
      font-weight: bold;
      color: #3a3a3a;
    }

    .show-config {
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 80px;

      .show-text {
        font-size: 13px;
        color: #3a3a3a;
        line-height: 13px;
      }
    }
  }
  .item-content {
    .nav-content {
      margin-top: 20px;
      display: flex;
      position: relative;
      .nav-title {
        line-height: 30px;
        font-size: 14px;
        color: #3a3a3a;
        margin-right: 18px;
        min-width: 56px;
      }

      .radio-group {
        margin-left: auto;
      }
      .nav-btn {
        width: 100%;
        height: 30px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .img-info {
        color: #c0c4cc;
        position: absolute;
        bottom: 0;
        left: 150px;
        font-size: 12px;
        line-height: 17px;
      }

      .link-selected {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .link-name {
          width: 175px;
          ::v-deep.el-input__inner {
            text-overflow: ellipsis;
            white-space: nowrap;
            background-color: #fff;
            color: #3a3a3a;
            cursor: text;
          }
        }
        ::v-deep.el-button--text {
          padding: 0;
        }
      }
    }
  }
}
.el-input,
.el-select {
  ::v-deep.el-input__inner {
    height: 30px;
  }
  ::v-deep.el-input__icon {
    line-height: 30px;
  }
}

.nav-item + .nav-item {
  margin-top: 24px;
}

.nav-tip {
  font-size: 12px;
  color: #ff5350;
  line-height: 12px;
  margin-top: 10px;
}
.drag-list {
  ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: rgba(53, 118, 255, 0.15);
    border: 1px solid #3576ff;
    color: #3576ff;
    box-shadow: none;
  }
}
</style>
