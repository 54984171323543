<template>
  <div class="color-picker">
    <el-button class="reset-btn" type="text" @click="resetColor">重置</el-button>
    <el-color-picker class="picker" v-model="value" :show-alpha="showAlpha"></el-color-picker>
  </div>
</template>

<script>
export default {
  name: "StColorPicker",
  model: {
    prop: "color",
    event: "colorChange",
  },
  props: {
    color: {
      type: String,
    },
    defaultColor: {
      type: String,
      default: "#ffffff",
    },
    // 是否可调节透明度
    showAlpha: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    value: {
      get() {
        return this.color;
      },
      set(val) {
        this.$emit("colorChange", val);
      },
    },
  },
  methods: {
    resetColor() {
      this.value = this.defaultColor;
    },
  },
};
</script>

<style lang='scss' scoped>
.color-picker {
  float: right;
  display: flex;

  .reset-btn {
    padding: 0px;
    margin-right: 10px;
  }

  .picker {
    ::v-deep.el-color-picker__trigger {
      width: 60px;
      height: 30px;
    }
    ::v-deep.el-color-picker__icon {
      display: none;
    }
  }
}
</style>
