<template>
  <div class="base-form-item">
    <div class="base-form-item-box">
      <div class="base-form-item-inner" :class="[vertical ? 'vertical' : 'inline']">
        <div class="base-form-item-label">
          <div v-if="required" class="form-required">*</div>
          {{handleSaaSVersionKeyword(label) || ''}}
        </div>

        <p v-if="vertical && description" class="base-form-item-tips"><i class="el-icon-info"/>{{description}}</p>

        <div class="base-form-item-content">
          <slot></slot>
        </div>
      </div>

      <p v-if="!vertical && description" class="base-form-item-tips"><i class="el-icon-info"/>{{description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFormItem",
  props: {
    label: {
      type: String,
      default: "",
    },
    description: String,
    required: {
      type: Number,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.base-form-item {
  width: 100%;
  height: 100%;
  padding: 0 18px;

  .base-form-item-box {
    padding: 0 15px;
    height: 100%;
    background-color: white;
  }

  .base-form-item-inner {
    height: 100%;
    display: flex;
    min-height: 56px;

    &.inline {
      justify-content: space-between;

      .base-form-item-label {
        @include nowrap();
      }
    }

    &.vertical {
      flex-direction: column;

      .base-form-item-label {
        padding-bottom: 0;
      }

      .base-form-item-content {
        width: 100%;
      }
    }
  }

  .base-form-item-label {
    min-width: 70px;
    font-size: 14px;
    font-weight: bold;
    color: #191e29;
    position: relative;
    padding: 18px 0 18px 8px;

    .form-required {
      position: absolute;
      left: 0;
      font-size: 14px;
      font-weight: bold;
      color: #dc193e;
      line-height: inherit;
    }

    &+.base-form-item-tips {
      margin-top: 12px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .base-form-item-content {
    flex: 1;
    min-width: 70px;
  }

  .base-form-item-tips {
    text-align: justify;
    line-height: 16px;
    font-size: 13px;
    color: #9d9ea0;
    /*padding-top: 7px;*/
    margin-top: -8px;
    padding-bottom: 18px;
    word-break: break-word;
    white-space: pre-wrap;

    .el-icon-info {
      margin-right: 5px;
      display: inline-block;
    }
  }
}
</style>
