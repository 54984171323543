const currentcyNormal = {
  privacy_default_value: function () {
    return (val, rule, fApi) => {
      rule.display = fApi.form.privacy_fix_user === 1
    }
  },
  value: function () {
    return (val, rule, fApi) => {
      if (fApi.form.placeholder !== undefined) {
        rule.display = false
      } else {
        rule.display = true
      }
    }
  },
  placeholder: function () {
    return (val, rule, fApi) => {
      rule.display = !fApi.form.value
    }
  }
}
const restrictionNormal = {
  showConfig: {
    dependence: function (vm) {
      return (val, rule, fApi, init) => {
        if (init) {
          rule.props.form = vm.myValue // 给到整个表单组件列表配置
        }
      }
    },
  },
}
const update = {
  'base-title': {
    ...restrictionNormal
  },
  'base-image-picker': {
    ...restrictionNormal
  },
  'base-file-picker': {
    ...restrictionNormal
  },
  /**
   * 不能有空格
   * @param {object} vm 表单的vue实例对象
   */
  nickname: {
    normal: {
      maxLength: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.validate !== ''
        }
      },
    },
  },
  unit_username: {
    normal: {
      maxLength: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.validate !== ''
        }
      },
    },
  },
  unit_aliasname: {
    normal: {
      maxLength: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.validate !== ''
        }
      },
    },
  },
  description: {
    normal: {
      maxLength: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.validate
        }
      },
      ...currentcyNormal,
    },
  },
  native_place: {
    normal: {
      showType: function () {
        return (val, rule, fApi) => {
          fApi.form.showType =
            fApi.form.showType === undefined ? 3 : fApi.form.showType
        }
      },
      ...currentcyNormal,
    },
  },
  'base-multi-select': {
    normal: {
      description: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.multiple === 1
        }
      },
    },
    ...restrictionNormal
  },
  'base-time-picker': {
    normal: {
      dateType: function () {
        return (val, rule, fApi) => {
          fApi.form.dateType =
            fApi.form.dateType === undefined ? 2 : fApi.form.dateType
        }
      },
      ...currentcyNormal,
    },
  },
  'base-area-picker': {
    normal: {
      showType: function () {
        return (val, rule, fApi) => {
          fApi.form.showType =
            fApi.form.showType === undefined ? 1 : fApi.form.showType
        }
      },
      ...currentcyNormal,
    },
  },
  unit_area: {
    normal: {
      showType: function () {
        return (val, rule, fApi) => {
          fApi.form.showType =
            fApi.form.showType === undefined ? 1 : fApi.form.showType
        }
      },
      ...currentcyNormal,
    },
  },
  'base-address-picker': {
    normal: currentcyNormal,
  },
  'base-input': {
    normal: currentcyNormal,
    ...restrictionNormal
  },
  email: {
    normal: currentcyNormal,
  },
  work_place: {
    normal: currentcyNormal,
  },
  work_role: {
    normal: currentcyNormal,
  },
  'base-select': {
    normal: currentcyNormal,
    ...restrictionNormal
  },
  birthday: {
    normal: {
      dateType: function () {
        return (val, rule, fApi) => {
          fApi.form.dateType =
            fApi.form.dateType === undefined ? 2 : fApi.form.dateType
        }
      },
      ...currentcyNormal,
    },
  },
  phone: {
    normal: currentcyNormal,
  },
  social_role: {
    normal: currentcyNormal,
  },
  cert_type: {
    normal: currentcyNormal,
  },
  school_graduate: {
    normal: currentcyNormal,
  },
  gender: {
    normal: currentcyNormal,
  },
  tall: {
    normal: currentcyNormal,
  },
  weight: {
    normal: currentcyNormal,
  },
  national: {
    normal: currentcyNormal,
  },
  political_status: {
    normal: currentcyNormal,
  },
  HongKong_and_Macao_identity: {
    normal: currentcyNormal,
  },
  people_type: {
    normal: currentcyNormal,
  },
  school_degree: {
    normal: currentcyNormal,
  },
  school_history: {
    normal: currentcyNormal,
  },
  member_type: {
    normal: currentcyNormal,
  },
  home_address: {
    normal: currentcyNormal,
  },
  hometown_address: {
    normal: currentcyNormal,
  },
  school_idcard: {
    normal: currentcyNormal,
  },
  page: {
    page_config: {
      addresses: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.address_limit === 1
        }
      },
      agreement_title: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.use_agreement === 1
          vm.rulesArr.agreementConfig.rules.forEach((r) => {
            if (r.field === 'titleConfig') {
              r.value.name = val
              r.value.isShow = fApi.form.use_agreement
            }
          })
        }
      },
      agreement_content: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.use_agreement === 1
          vm.rulesArr.agreementConfig.rules.forEach((r) => {
            if (r.field === 'customContent') {
              r.value = val
            }
          })
        }
      },
    },
    agreementConfig: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          vm.rulesArr.agreementConfig.rules.forEach((r) => {
            if (r.field === 'customContent') {
              r.display = val === 1
            }
            if (r.field === 'titleConfig') {
              r.display = val === 1
              r.value.isShow = val
            }
          })
        }
      },
    },
  },
}

export default update
