<template>
  <div class="restrict-selector el-form-item__label" style="width: 100%;white-space: pre-wrap" v-if="myValue">
        <p class="info">当"单选框"组件中的某个选项被选中时显示该组件</p>

    <!--    <st-radio-button-group-->
    <!--            v-model=""-->
    <!--      label="状态"-->
    <!--      :options="[-->
    <!--        {-->
    <!--          label: '启用',-->
    <!--          value: 1,-->
    <!--        },-->
    <!--        {-->
    <!--          label: '禁用',-->
    <!--          value: 0,-->
    <!--        },-->
    <!--        {-->
    <!--          label: '重置',-->
    <!--          value: -1,-->
    <!--        },-->
    <!--      ]"-->
    <!--    >-->
    <!--    </st-radio-button-group>-->
<template v-if="false">
    当       <el-select :disabled="!isLabelUnique" clearable size="small" v-model="myValue.label">
    <!-- 注意：这里不能选自身，所以要禁用 -->
    <el-option
        :disabled="
            form ? form.normal.label === item.props.normal.label : false
          "
        :key="item.props.normal.label"
        :label="item.props.normal.label"
        :value="item.props.normal.label"
        v-for="item in radioList"
    ></el-option>
  </el-select> 的选项       <el-select :disabled="!isLabelUnique" clearable size="small" v-model="myValue.option">
    <template v-if="myValue.label">
      <template
          v-if="
              radioList.filter((li) => li.props.normal.label === myValue.label)
                .length
            "
      >
        <el-option
            :key="item.timestamp || item.id || item.toString()"
            :value="typeof item === 'string' ? item : item.text"
            v-for="item in radioList.find(
                (li) => li.props.normal.label === myValue.label
              ).props.normal.options"
        >{{ typeof item === 'string' ? item : item.text }}</el-option
        >
      </template>
    </template>
  </el-select> 被选中时，显示该组件</template>

<!--<template v-if="false">-->

    <!-- /多 -->
<!--    <el-form-item label="当"></el-form-item>-->
    <el-form-item label="单选框">
      <el-select :disabled="!isLabelUnique" clearable v-model="myValue.label">
        <!-- 注意：这里不能选自身，所以要禁用 -->
        <el-option
          :disabled="
            form ? form.normal.label === item.props.normal.label : false
          "
          :key="item.props.normal.label"
          :label="item.props.normal.label"
          :value="item.props.normal.label"
          v-for="item in radioList"
        ></el-option>
      </el-select>
    </el-form-item>
<!--    <el-form-item label="的"></el-form-item>-->
    <el-form-item label="选项">
      <el-select :disabled="!isLabelUnique" clearable v-model="myValue.option">
        <template v-if="myValue.label">
          <template
            v-if="
              radioList.filter((li) => li.props.normal.label === myValue.label)
                .length
            "
          >
            <el-option
              :key="item.timestamp || item.id || item.toString()"
              :value="typeof item === 'string' ? item : item.text"
              v-for="item in radioList.find(
                (li) => li.props.normal.label === myValue.label
              ).props.normal.options"
              >{{ typeof item === 'string' ? item : item.text }}</el-option
            >
          </template>
        </template>
      </el-select>
    </el-form-item>
<!--    <el-form-item label="被选中时，显示该组件"></el-form-item>-->
    <!--    <el-form-item label=""></el-form-item>-->

<!--</template>-->

  </div>
</template>

<script>
// import StRadioButtonGroup from '../../../../mail/components/Company/FormComponents/StRadioButtonGroup'
export default {
  // 动态组件调用，必须显示声明 name 属性
  name: 'RestrictSelector',
  // components: { StRadioButtonGroup },
  inject: ['getList'],
  props: {
    // formConfigs: {
    //   type: Array,
    //   default: () => []
    // },
    form: Object,
    value: Object,
  },
  computed: {
    list() {
      return this.getList()
    },
    radioList() {
      // return this.list.filter((li) => li.field === 'base-select' || li.field === 'base-multi-select')
      return this.list.filter((li) => li.field === 'base-select')
    },
    myValue: {
      get() {
        // return this.$mergeModel({ label: '', option: '' }, this.value) // 此会失去响应式
        return this.value
      },
      set(val) {
        this.$emit('input', {
          label: val.label,
          option: val.option,
        })
      },
    },
    isLabelUnique() {
      let flag = true
      // 表单标题查重
      this.list.forEach((el1, i) => {
        this.list.forEach((el2, j) => {
          if (
            (el2.props.normal.label || el2.props.title) ===
              (el1.props.normal.label || el1.props.title) &&
            i !== j
          ) {
            if (flag) {
              flag = false
              return flag
            }
          }
        })
      })
      return flag
    },
  },
  watch: {
    'myValue.label'(newValue) {
      if (!newValue) {
        this.myValue.option = ''
      }
    },
  },
}
</script>

<style scoped>
/*.restrict-selector {*/
/*  line-height: 32px;*/

/*  .el-form-item {*/
/*    margin-bottom: 8px !important;*/
/*  }*/
/*}*/

.info {
  color: #c0c4cc;
  font-size: 12px;
  margin-bottom: 12px;
}
</style>
