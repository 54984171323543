<template>
  <div class="st-select st-form-item" :style="{
    marginBottom: myConfig.normal.marginBottom + 'px'
  }">
    <base-form-item
        :description="myConfig.normal.description"
        :label="myConfig.normal.label" :required="myConfig.normal.required" vertical>
      <div class="flex w-100" style="padding: 20px 0;">
        <div class="cert-box flex flex-col flex-center">
          <div class="cert-img w-100 flex-center">
            <i class="el-icon-plus" />
          </div>
<!--          <span class="cert-face">正面</span>-->
        </div>
<!--        <div class="cert-box flex flex-col flex-center">-->
<!--          <div class="cert-img w-100 flex-center">-->
<!--            <i class="el-icon-plus" />-->
<!--          </div>-->
<!--          <span class="cert-face">反面</span>-->
<!--        </div>-->
      </div>
    </base-form-item>
  </div>
</template>

<script>
  import BaseFormItem from "./BaseFormItem";
  export default {
    name: "StSchoolIdCard",
    components: {BaseFormItem},
    props: {
      config: {
        type: Object,
        default: function () {
          return {};
        },
      },
    },
    data() {
      return {
        // 定义 config 格式和默认数据
        model: {
          normal: {
            "marginBottom": 0,
            "required": 1,
            "label": "标题内容",
            description: ""
          },
        }
      }
    },
    computed: {
      myConfig() {
        return this.$mergeModel(this.model, this.config);
      },
    },
  }
</script>

<style lang="scss" scoped>
  .w-100 {
    width: 100%;
  }

  .cert-box {
    width: 100%;
    position: relative;
    max-width: 50%;

    &:before {
      content: "";
      display: block;
      padding-top: 57%;
      width: 100%;
    }

    .el-icon-plus {
      font-size: 24px;
    }

    .cert-img {
      position: absolute;
      top: 0;
      border-radius: 8px;
      overflow: hidden;
      /*height: calc(100% - 25px);*/
      height: 100%;
      border: 0.5px dashed black;
    }

    .cert-face {
      height: 25px;
      padding-top: 11px;
      font-size: 14px;
    }
  }

  .cert-box + .cert-box {
    margin-left: 14px;
  }
</style>
