<template>
  <div style="margin-bottom: 24px;">
    <p class="title">{{label}}</p>
    <div class="handle-area">
      <el-slider class="slider" v-model="value" :min="min" :max="max" show-input></el-slider>
      <!--      <el-input-number class="number-input" v-model="value" :min="min" :max="max"></el-input-number>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "StNumberSlider",
  model: {
    prop: "num",
    event: "numChange",
  },
  props: {
    label: {
      type: String,
    },
    num: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {};
  },
  computed: {
    value: {
      get() {
        return this.num;
      },
      set(val) {
        this.$emit("numChange", val);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.title {
  line-height: 14px;
  margin-bottom: 18px;
  font-size: 14px;
  color: #606266;
}
.handle-area {
  width: 100%;
  display: flex;
  .slider {
    flex: 1;
    /*padding-left: 10px;*/
  }
}
</style>
