<template>
  <div class="config-title">
    <div class="form-title">
      <p>{{title}}</p>
      <el-switch v-if="!showCheck" v-model="show" :active-value="1" :inactive-value="0">
      </el-switch>
      <div v-else class="form-check"><span>显示</span>
        <el-checkbox :checked="isShow === 1" @change="handleChange"></el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StIsShowTitle",
  model: {
    prop: "isShow",
    event: "isShowChange",
  },
  props: {
    title: {
      type: String,
      default: "显示设置",
    },
    isShow: {
      type: Number,
      default: 0,
    },
    showCheck: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(val) {
        this.$emit("isShowChange", val);
      },
    },
  },
  methods: {
    handleChange(val) {
      this.show = val ? 1 : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    flex-shrink: 0;
    margin-right: 20px;
  }

  .form-check {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span {
      font-size: 14px;
      color: #3a3a3a;
      font-weight: 400;
    }
  }
}
</style>