var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-title flex-col",style:({
    marginBottom: _vm.myConfig.normal.marginBottom + 'px',
    paddingBottom: _vm.myConfig.normal.description ? '' : '10px',
  })},[_c('p',{staticClass:"__content",style:([
      _vm.myConfig.normal.textConfig,
      {
        fontSize: ((_vm.myConfig.normal.fontSize) + "px"),
      } ])},[_vm._v(_vm._s(_vm.myConfig.normal.value))]),(_vm.myConfig.normal.description)?_c('p',{staticClass:"base-form-item-tips __content"},[_c('i',{staticClass:"el-icon-info"}),_vm._v(_vm._s(_vm.myConfig.normal.description)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }