<!--
 * @Author: dongjia
 * @Date: 2021-08-25 16:17:26
 * @LastEditTime: 2021-08-25 18:01:23
 * @LastEditors: aleaner
 * @Description: 标题宽度自由的输入框
 * @FilePath: \app-design\components\Form\StNativeInput.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="native-input">
    <p class="input-label" :style="{width: labelWidth || '96px'}">{{ label }}</p>
    <el-input
      :type="type"
      style="width: 100%"
      size="small"
      :autosize="{ minRows: 5, maxRows: 8 }"
      v-model="value"
      :placeholder="placeholder"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'StNativeInput',
  model: {
    prop: 'inputVal',
    event: 'input',
  },
  props: {
    inputVal: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelWidth: String,
    placeholder: {
      type: String,
      default: '请输入',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    value: {
      get() {
        return this.inputVal
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.native-input {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .input-label {
    width: 96px;
    font-size: 14px;
    flex-shrink: 0;
    // margin-right: 12px;
    color: #606266;
  }
}
</style>
