<template>
  <el-radio-group v-model="myValue" class="radio-group" @change="change">
    <el-radio-button v-for="(option, index) in options" :key="option.value" :label="option.value">
      {{option.label || option}}</el-radio-button>
  </el-radio-group>
</template>

<script>
export default {
  name: "StRadioButtonGroup",
  props: {
    options: {
      type: Array,
    },
    value: {},
  },
  data() {
    return {
      myValue: "",
    };
  },
  watch: {
    value(val) {
      this.myValue = val;
    },
  },
  created() {
    this.myValue = this.value;
  },
  methods: {
    change() {
      this.$emit("input", this.myValue);
      this.$emit("change", this.myValue);
    },
  },
};
</script>

<style lang='scss' scoped>
.radio-group {
  float: right;
  ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: rgba(53, 118, 255, 0.15);
    border: 1px solid #3576ff;
    color: #3576ff;
    box-shadow: none;
  }
}
// .el-radio-group {
//   :nth-child(1) {
//     ::v-deep.el-radio-button__inner {
//       border-right: none;
//     }
//   }
// }
</style>
