<template>
  <div class="st-bottom-button">
    <div class="button-content">
      {{myConfig.normal.btnText || '提交申请'}}
    </div>
  </div>
</template>

<script>
export default {
  name: "st-bottom-button",
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          btnText: "",
          interactiveType: "",
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  created() {
    // console.log('st-nav', this.config, JSON.stringify(this.config));
  },
};
</script>

<style lang="scss" scoped>
.st-bottom-button {
  padding: 13px 18px;
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(238, 238, 238, 0.5);

  .button-content {
    text-align: center;
    width: 100%;
    height: 100%;
    background: #3a7aff;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 44px;
  }
}
</style>
