<template>
  <div class="st-color-picker" :class="[!!label?'el-form-item el-form-item--small':'color-picker']">
    <template v-if="!label">
      <el-button class="reset-btn" type="text" @click="resetColor">重置</el-button>
      <el-color-picker class="picker" v-model="value" :show-alpha="showAlpha" @click.native="handleClick" />
    </template>
    <el-form-item v-else :label="label" style="margin-bottom: 0;">
      <div class="flex-center color-picker">
        <el-button class="reset-btn" type="text" @click="resetColor">重置</el-button>
        <el-color-picker class="picker" v-model="value" :show-alpha="showAlpha" @click.native="handleClick" />
      </div>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "StColorPicker",
  model: {
    prop: "color",
    event: "colorChange",
  },
  props: {
    color: {
      type: String,
    },
    defaultColor: {
      type: String,
      default: "#ffffff",
    },
    // 是否可调节透明度
    showAlpha: {
      type: Boolean,
      default: true,
    },
    label: String,
  },
  data() {
    return {};
  },
  computed: {
    value: {
      get() {
        return this.color;
      },
      set(val) {
        this.$emit("colorChange", val);
      },
    },
  },
  methods: {
    resetColor() {
      this.value = this.defaultColor;
    },
    handleClick() {
      let icon = document.createElement('i')
      icon.classList.add('el-icon-info')
      icon.style.fontSize = '12px'
      icon.style.color = '#c0c4cc'
      icon.style.marginRight = '5px'

      let p = document.createElement("p")
      p.style.fontSize = '12px'
      p.style.color = '#c0c4cc'
      p.style.marginTop = '5px'

      p.append(icon, '支持输入 #fff、#ffffff、rgb(255, 255, 255)、rgba(255, 255, 255, 1) 格式')

      document.querySelectorAll('.el-color-dropdown').forEach(el => {
        if (el.lastElementChild.tagName.toLowerCase() !== 'p') {
          el.append(p)
        }
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.color-picker {
  float: right;
  display: flex;

  .reset-btn {
    padding: 0px;
    margin-right: 10px;
  }

  .picker {
    ::v-deep.el-color-picker__trigger {
      width: 60px;
      height: 30px;
    }
    ::v-deep.el-color-picker__icon {
      display: none;
    }
  }
}
</style>
