<template>
  <el-form-item class="radio-button" :label="title || label" :label-width="labelWidth">
    <el-radio-group v-model="myValue" size="small" class="radio-group" @change="change">
      <!-- 这里option.value 不能简单用 || 判断，因为存在option.value === 0 的情况 -->
      <el-radio-button v-for="(option, index) in options" :key="option.value"
                       :label="option.value !== undefined ? option.value : option"
                       :disabled="disabled && option.value === 'page'">
        {{option.label || option}}</el-radio-button>
    </el-radio-group>
    <p class="form-tips" v-if="formTip">{{formTip}}</p>
  </el-form-item>
</template>

<script>
export default {
  name: "StRadioButtonGroup",
  props: {
    options: {
      type: Array,
    },
    title: {
      type: String,
      default: "",
    },
    label: String,
    formTip: {
      type: String,
      default: "",
    },
    value: {},
    labelWidth: String,
    bind: {
      type: Boolean,
      default: true
    },
    field: String
  },
  data() {
    return {
      myValue: "",
    };
  },
  watch: {
    value(val) {
      this.myValue = val;
    },
  },
  computed: {
    disabled() {
      return this.$store.getters["appDesign/articlepage"];
    },
  },
  created() {
    this.myValue = this.value;
  },
  methods: {
    change() {
      if (this.bind) this.$emit("input", this.myValue);
      else if (this.field === 'style') {
        this.$bus.emit('switch-year-theme', {
          "style": this.myValue // default：为还原最新备份，rabbit_year：兔年风格
        })
      }
      this.$emit("change", this.myValue);
    },
  },
};
</script>

<style lang='scss' scoped>
.radio-button {
  width: 100%;

  .form-tips {
    text-align: justify;
    line-height: 16px;
    font-size: 13px;
    color: #9d9ea0;
    padding-top: 7px;
  }

  .radio-group {
    ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background: rgba(53, 118, 255, 0.15);
      border: 1px solid #3576ff;
      color: #3576ff;
      box-shadow: none;
    }
  }

  .radio-group, .form-tips {
    float: right;
  }
}
</style>
