<template>
  <div class="uncheck-box">
    <el-checkbox disabled :checked="value===1" label="默认"></el-checkbox>
  </div>
</template>

<script>
export default {
  name: "StUnCheckableBox",
  model: {
    prop: "type",
    event: "typeChange",
  },
  props: {
    type: {
      type: Number,
    },
  },
  computed: {
    value: {
      get() {
        return this.type;
      },
      set(val) {
        this.$emit("typeChange", val);
      },
    },
  },
};
</script>

<style lang='scss' scoped>
.uncheck-box {
  float: right;
  display: flex;
}
</style>
