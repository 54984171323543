<!--
 * @Author: dongjia
 * @Date: 2021-07-19 11:51:51
 * @LastEditTime: 2021-07-20 14:32:41
 * @LastEditors: aleaner
 * @Description: 特殊的输入框
 * @FilePath: \app-design\components\Form\StSpecialInput.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="st-special-input input-box">
    <template v-if="advanced">
      <el-form-item :label="label || title">
        <div class="input-area flex-center">
          <el-input
            v-if="typeof inputValue === 'string'"
            :disabled="!inputEnable"
            class="input"
            v-model="value"
            placeholder="请输入"
            clearable
            :maxlength="inputLength !== -1 ? inputLength : 200"
            :show-word-limit="inputLength !== -1"
          >
          </el-input>
          <el-input
            v-if="typeof inputValue !== 'string' && value.name !== undefined"
            :disabled="!inputEnable"
            class="input"
            v-model="value.name"
            placeholder="请输入"
            clearable
            :maxlength="inputLength !== -1 ? inputLength : 200"
            :show-word-limit="inputLength !== -1"
          >
          </el-input>
          <el-input
            v-if="typeof inputValue !== 'string' && value.ID !== undefined"
            :disabled="!inputEnable"
            class="input"
            v-model="value.ID"
            placeholder="请输入ID"
            clearable
            :maxlength="inputLength !== -1 ? inputLength : 200"
            :show-word-limit="inputLength !== -1"
          >
          </el-input>
          <el-checkbox
            style="margin-left: 17px"
            v-if="typeof inputValue !== 'string' && rightBtnType === 'checkbox'"
            v-model="value.isShow"
            :true-label="0"
            :false-label="1"
            >隐藏</el-checkbox
          >
          <el-button
            type="text"
            class="r-btn"
            v-if="typeof inputValue !== 'string' && rightBtnType === 'button'"
            @click="getContactInfo"
            :loading="saveLoading"
            >保存
          </el-button>
        </div>
        <p
          class="form-tips"
          v-if="formTip && formTip.indexOf(hrefConfig.text) !== -1"
        >
          <span class="el-icon-info el-icon"></span
          >{{ formTip.match(new RegExp('([\\S\\s]*)' + hrefConfig.text))[1] }}
          <a :href="hrefConfig.url" target="_blank">{{ hrefConfig.text }}</a>
          {{ formTip.match(new RegExp(hrefConfig.text + '([\\S\\s]*)'))[1] }}
        </p>
        <p class="form-tips" v-if="formTip && hrefConfig.text === ''">
          <span class="el-icon-info"></span>{{ formTip }}
        </p>
      </el-form-item>
      <st-radio-button-group
        v-show="value.isShow"
        title="文本粗细"
        v-model="value.fontWeight"
        :options="fontWeightOptions"
      />
      <st-number-slider
        v-show="value.isShow"
        label="文本字号"
        v-model="value.fontSize"
        :min="12"
        :max="24"
      />
      <st-color-picker
        v-show="value.isShow"
        label="文本颜色"
        v-model="value.fontColor"
        default-color="#000000"
      />
    </template>
    <template v-else>
      <div class="input-area flex-center">
        <el-input
          v-if="typeof inputValue === 'string'"
          class="input"
          v-model="value"
          placeholder="请输入"
        >
        </el-input>
        <el-input
          v-if="typeof inputValue !== 'string' && value.name !== undefined"
          class="input"
          v-model="value.name"
          placeholder="请输入"
        >
        </el-input>
        <el-input
          v-if="typeof inputValue !== 'string' && value.ID !== undefined"
          class="input"
          v-model="value.ID"
          placeholder="请输入ID"
        >
        </el-input>
        <el-checkbox
          style="margin-left: 17px"
          v-if="typeof inputValue !== 'string' && rightBtnType === 'checkbox'"
          v-model="value.isShow"
          :true-label="0"
          :false-label="1"
          >隐藏</el-checkbox
        >
        <el-button
          type="text"
          class="r-btn"
          v-if="typeof inputValue !== 'string' && rightBtnType === 'button'"
          @click="getContactInfo"
          :loading="saveLoading"
          >保存
        </el-button>
      </div>
      <p
        class="form-tips"
        v-if="formTip && formTip.indexOf(hrefConfig.text) !== -1"
      >
        <span class="el-icon-info el-icon"></span
        >{{ formTip.match(new RegExp('([\\S\\s]*)' + hrefConfig.text))[1] }}
        <a :href="hrefConfig.url" target="_blank">{{ hrefConfig.text }}</a>
        {{ formTip.match(new RegExp(hrefConfig.text + '([\\S\\s]*)'))[1] }}
      </p>
      <p class="form-tips" v-if="formTip && hrefConfig.text === ''">
        <span class="el-icon-info"></span>{{ formTip }}
      </p>
    </template>
    <!-- <slot></slot> -->
  </div>
</template>

<script>
import { ContactInfo } from '../../api/components'
import StRadioButtonGroup from '@/modules/app-design/components/Form/StRadioButtonGroup'
import StNumberSlider from '@/modules/app-design/components/Form/StNumberSlider'
import StColorPicker from '@/modules/app-design/components/Form/StColorPicker'

/**
 * @property inputValue v-model
 * @property {String} rightBtnType = [checkbox|button] 输入框右侧按钮
 */
export default {
  name: 'StSpecialInput',
  components: { StColorPicker, StNumberSlider, StRadioButtonGroup },
  model: {
    prop: 'inputValue',
    event: 'change',
  },
  props: {
    inputValue: {
      type: [String, Object],
      default: '',
    },
    // 加一个禁用输入的功能
    inputEnable: {
      type: Boolean,
      default: true,
    },
    // 最大输入长度
    inputLength: {
      type: Number,
      default: -1,
    },
    rightBtnType: {
      type: String,
      default: 'checkbox',
    },
    formTip: {
      type: String,
      default: '',
    },
    hrefConfig: {
      type: Object,
      default: () => {
        return {
          text: '',
          url: '',
        }
      },
    },
    /**
     * 高级选项
     */
    advanced: Boolean,
    label: String,
    title: String,
  },
  data() {
    return {
      saveLoading: false,
      fontWeightOptions: [
        { label: '常规体', value: 'normal' },
        { label: '加粗体', value: 'bold' },
      ],
    }
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit('change', val)
      },
    },
  },
  computed: {
    value: {
      get() {
        return this.inputValue
      },
      set(val) {
        this.$emit('change', val)
      },
    },
  },
  methods: {
    getContactInfo() {
      if (this.value.ID) {
        this.saveLoading = true
        ContactInfo({ config_id: this.value.ID })
          .then((res) => {
            const { data } = res
            this.value.configType = data.type
            this.saveLoading = false
          })
          .catch((err) => {
            this.saveLoading = false
          })
      } else {
        this.$message.error('请输入配置ID')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.input-box {
  .input {
    width: 100%;
  }

  .input-area {
    width: 100%;
  }

  .form-tips {
    text-align: justify;
    line-height: 16px;
    font-size: 13px;
    color: #9d9ea0;
    padding-top: 7px;
    // .tip-icon {
    //   font-size: 15px;
    //   margin-right: 5px;
    // }
  }
  a {
    text-decoration: none;
    color: #3576ff;
  }

  .el-icon {
    margin-right: 3px;
  }
  .r-btn {
    padding: 8px 13px;
  }
}
</style>
