var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-middle",staticStyle:{"--main-color":"#3a7aff"}},[_c('div',{staticClass:"workspace-middle-box",on:{"click":function($event){return _vm.updateSelected('page')}}},[_c('div',{staticClass:"workspace-middle-page"},[_c('div',{class:['drag-item no-hover'],style:({ height: 64 + 'px', position: 'absolute' })},[_c('div',{staticClass:"header-fixed",style:({ height: '64px', backgroundColor: 'white' })},[_c('img',{class:['default-back-icon'],attrs:{"src":require("../../../assets/images/Back1@2x.png")}}),_c('img',{staticClass:"status-bar",attrs:{"src":require("../../../assets/images/phone-statusbar1@2x.png")}})])]),_c('div',{staticClass:"workspace-middle-page-content"},[_c('div',{staticClass:"app-nav",class:['drag-item no-hover']},[_c('st-page-set',{attrs:{"config":_vm.page.props}})],1),_c('draggable',{class:['draggable-box'],attrs:{"draggable":".drag-item","animation":"400","ghostClass":"ghost","options":_vm.listOptions,"move":_vm.onMove,"filter":".no-drag","disabled":!_vm.editable},on:{"sort":_vm.handleSort,"add":_vm.handleAdd},model:{value:(_vm.myList),callback:function ($$v) {_vm.myList=$$v},expression:"myList"}},[_vm._l((_vm.myList),function(li,i){return _c('div',{key:li.extra.timestamp,ref:"formItem",refInFor:true,staticClass:"drag-item",class:[
              _vm.value === i ? 'active' : '',
              ['st-custom-header', 'st-custom-nav'].includes(li.component)
                ? 'no-drag'
                : '',
              (li.title &&
                _vm.checkSameTitle(
                  li.props.normal.label || li.props.title,
                  _vm.myList,
                  i
                )) ||
              _vm.errorIndex === i
                ? 'error'
                : '' ],attrs:{"id":'formItem' + i,"data-last":li.props.normal && li.props.normal.marginBottom
                ? li.props.normal.marginBottom > 0
                : li.field === 'base-title'
                ? true
                : i + 1 < _vm.myList.length - 1 &&
                  _vm.myList[i + 1].field === 'base-title'
                ? true
                : false,"title":li.props.showConfig &&
              li.props.showConfig.dependence &&
              li.props.showConfig.dependence.option
                ? ("当【" + (li.props.showConfig.dependence.label) + "】的【" + (li.props.showConfig.dependence.option) + "】被选中时显示")
                : ''},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected(i)}}},[(li.is_show == undefined || li.is_show === 1)?_c(li.component,{tag:"component",attrs:{"config":li.props,"type":li.type,"formType":li.field,"field":li.field}}):_vm._e(),_c('div',{staticClass:"drag-item-tool"},[(!_vm.defaultFields.includes(li.field) && _vm.editable)?_c('div',{staticClass:"tools"},[_c('img',{attrs:{"src":require("../../../assets/images/del_gray.png")},on:{"click":function($event){$event.stopPropagation();return _vm.deletePageItem(i)}}})]):_vm._e(),(li.title)?_c('div',{staticClass:"arrow-label",style:({
                  display:
                    _vm.defaultFields.includes(li.field) || !_vm.editable
                      ? 'block'
                      : '',
                })},[_vm._v(" "+_vm._s(li.title)+" "),(
                    li.props.showConfig &&
                    li.props.showConfig.dependence &&
                    li.props.showConfig.dependence.option
                  )?_c('i',{staticClass:"el-icon-link",attrs:{"title":("当【" + (li.props.showConfig.dependence.label) + "】的【" + (li.props.showConfig.dependence.option) + "】被选中时显示")}}):_vm._e()]):_vm._e()]),_c('div',{staticClass:"drag-item-tool-left"},[(
                  li.title &&
                  _vm.checkSameTitle(
                    li.props.normal.label || li.props.title,
                    _vm.myList,
                    i
                  )
                )?_c('div',{staticClass:"disable-label",style:({
                  right:
                    168 +
                    (li.props.normal.label || li.props.title).length * 12 +
                    'px',
                })},[_vm._v(" 不能填写相同的标题："+_vm._s(li.props.normal.label || li.props.title)+" ")]):_vm._e()])],1)}),_c('div',{staticClass:"drag-item no-hover hide",staticStyle:{"height":"1px","background-color":"transparent"}}),(_vm.myList.length)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAgreement),expression:"isAgreement"}],staticClass:"drag-item login-tip no-hover no-drag",class:[_vm.isAgreement ? '' : 'ignore']},[_c('div',{staticClass:"check-box"}),_c('div',{staticClass:"protocol-text"},[_vm._v(" 我已阅读并同意 "),_c('span',[_vm._v("《"+_vm._s(_vm.page.props && _vm.page.props.agreementConfig ? _vm.page.props.agreementConfig.titleConfig.name : '')+"》")])])]):_vm._e()],2),_c('div',{staticClass:"drag-item bottom-button no-hover",class:[_vm.value === 'bottomBtn' ? 'active' : '']},[_c('st-bottom-button',{attrs:{"config":_vm.bottomBtn.props}})],1)],1)]),_c('div',{staticClass:"workspace-middle-undo-manage"},[_c('img',{attrs:{"disabled":!_vm.undoManager.canUndo,"src":require("../../../assets/images/undo.png")},on:{"click":function($event){$event.stopPropagation();return _vm.undo.apply(null, arguments)}}}),_c('img',{attrs:{"disabled":!_vm.undoManager.canRedo,"src":require("../../../assets/images/redo.png")},on:{"click":function($event){$event.stopPropagation();return _vm.redo.apply(null, arguments)}}})]),_c('div',{staticClass:"workspace-middle-buttons"},[_c('el-button',{attrs:{"type":_vm.value === 'page' ? 'primary' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected('page')}}},[_c('span',{staticClass:"middle-button-content"},[(_vm.value === 'page')?_c('img',{attrs:{"src":require("../../../assets/images/page-setting-white.png")}}):_c('img',{attrs:{"src":require("../../../assets/images/page-setting.png")}}),_vm._v(" 页面设置")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }