var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.myValue)?_c('div',{staticClass:"restrict-selector el-form-item__label",staticStyle:{"width":"100%","white-space":"pre-wrap"}},[_c('p',{staticClass:"info"},[_vm._v("当\"单选框\"组件中的某个选项被选中时显示该组件")]),(false)?[_vm._v(" 当 "),_c('el-select',{attrs:{"disabled":!_vm.isLabelUnique,"clearable":"","size":"small"},model:{value:(_vm.myValue.label),callback:function ($$v) {_vm.$set(_vm.myValue, "label", $$v)},expression:"myValue.label"}},_vm._l((_vm.radioList),function(item){return _c('el-option',{key:item.props.normal.label,attrs:{"disabled":_vm.form ? _vm.form.normal.label === item.props.normal.label : false,"label":item.props.normal.label,"value":item.props.normal.label}})}),1),_vm._v(" 的选项 "),_c('el-select',{attrs:{"disabled":!_vm.isLabelUnique,"clearable":"","size":"small"},model:{value:(_vm.myValue.option),callback:function ($$v) {_vm.$set(_vm.myValue, "option", $$v)},expression:"myValue.option"}},[(_vm.myValue.label)?[(
              _vm.radioList.filter(function (li) { return li.props.normal.label === _vm.myValue.label; })
                .length
            )?_vm._l((_vm.radioList.find(
                function (li) { return li.props.normal.label === _vm.myValue.label; }
              ).props.normal.options),function(item){return _c('el-option',{key:item.timestamp || item.id || item.toString(),attrs:{"value":typeof item === 'string' ? item : item.text}},[_vm._v(_vm._s(typeof item === 'string' ? item : item.text))])}):_vm._e()]:_vm._e()],2),_vm._v(" 被选中时，显示该组件")]:_vm._e(),_c('el-form-item',{attrs:{"label":"单选框"}},[_c('el-select',{attrs:{"disabled":!_vm.isLabelUnique,"clearable":""},model:{value:(_vm.myValue.label),callback:function ($$v) {_vm.$set(_vm.myValue, "label", $$v)},expression:"myValue.label"}},_vm._l((_vm.radioList),function(item){return _c('el-option',{key:item.props.normal.label,attrs:{"disabled":_vm.form ? _vm.form.normal.label === item.props.normal.label : false,"label":item.props.normal.label,"value":item.props.normal.label}})}),1)],1),_c('el-form-item',{attrs:{"label":"选项"}},[_c('el-select',{attrs:{"disabled":!_vm.isLabelUnique,"clearable":""},model:{value:(_vm.myValue.option),callback:function ($$v) {_vm.$set(_vm.myValue, "option", $$v)},expression:"myValue.option"}},[(_vm.myValue.label)?[(
              _vm.radioList.filter(function (li) { return li.props.normal.label === _vm.myValue.label; })
                .length
            )?_vm._l((_vm.radioList.find(
                function (li) { return li.props.normal.label === _vm.myValue.label; }
              ).props.normal.options),function(item){return _c('el-option',{key:item.timestamp || item.id || item.toString(),attrs:{"value":typeof item === 'string' ? item : item.text}},[_vm._v(_vm._s(typeof item === 'string' ? item : item.text))])}):_vm._e()]:_vm._e()],2)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }