<template>
  <div class="component-checkbox">
    <div v-for="(item,index) in list" :key="index">
      <el-checkbox :false-label="0" :true-label="1" v-if="item.field !== 'base-title'" v-model="item.is_show"
        :disabled="disableForm.includes(item.field)">{{item.props.normal.label}}</el-checkbox>
      <el-checkbox :disabled="disableForm.includes(item.field)" :false-label="0" :true-label="1" v-else
        v-model="item.is_show">{{item.props.normal.value}}</el-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageComponentSelect",
  model: {
    prop: "componentList",
    event: "input",
  },
  props: {
    componentList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    list: {
      get() {
        return this.componentList;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      disableForm: [
        "nickname",
        "avatar",
        "gender",
        "phone",
        // "position_id",
        "invitation_code",
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.component-checkbox {
  padding-bottom: 40px;
  background-color: #fff;
}
.el-checkbox {
  padding: 16px 12px 8px;
  width: 100%;
}
</style>
