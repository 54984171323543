<template>
  <div style="margin-bottom: 24px;">
    <p class="title" v-if="title">{{title}}</p>
    <div class="radio-content">
      <el-radio :style="{
        marginBottom: index<options.length-(options.length%4?options.length%4:4)?'16px;':'',
        width:options.length>=4?'25%':100/options.length + '%'
      }" v-for="(item,index) in options" :key="index" v-model="value" :label="item.value">{{item.name}}
      </el-radio>
    </div>
  </div>
</template>

<script>
export default {
  name: "StTemplateRadio",
  model: {
    prop: "template",
    event: "templateChange",
  },
  props: {
    title: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    template: {
      type: [String, Number],
    },
  },
  computed: {
    value: {
      get() {
        return this.template;
      },
      set(val) {
        this.$emit("templateChange", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  line-height: 14px;
  margin-bottom: 24px;
  font-size: 14px;
  color: #606266;
}

.radio-content {
  display: flex;
  flex-wrap: wrap;
  .el-radio {
    margin-right: 0;
    padding-right: 10px;
  }
}
</style>
