<template>
  <div
    class="form-page"
    v-loading="loading"
    :class="header.isFullscreen ? 'fullscreen' : ''"
    :style="leftBarWidth"
  >
    <el-container
      v-if="library && library.user && library.user.length"
      class="layout"
    >
      <!-- 顶部菜单栏 -->
      <el-header class="menu-bar" style="height: 54px">
        <page-header-content @header-click="handleHeaderClick" />
      </el-header>
      <el-container class="workspace">
        <div
          class="workspace-left"
          style="width: auto; max-width: 50%; min-width: var(--left-barwidth)"
        >
          <!-- 左侧菜单 -->
          <div class="resize-bar" />
          <div class="resize-line" />
          <div class="resize-save">
            <!-- 左侧菜单 -->
            <page-left v-model="main.selected" :componentsList="[]" />
          </div>
        </div>
        <el-main class="workspace-middle">
          <page-middle
            v-model="main.selected"
            ref="pageMiddle"
            :page="main.page"
            :bottomBtn="main.bottomBtn"
            :list="main.componentList"
            @update-list="updateList"
          />
        </el-main>
        <el-aside class="workspace-right" style="width: 360px">
          <page-right
            :selected="main.selected"
            :page="main.page"
            :bottomBtn="main.bottomBtn"
            :list="main.componentList"
            @update-list="updateList"
          />
        </el-aside>
      </el-container>
    </el-container>
    <PreviewCodeDialog v-model="showPreviewqrCode" :data="qrCodeData" />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import PageMiddle from '@/modules/organization-admin/components/OrgFrame/Page/PageMiddle'
import {allField,} from '@/modules/organization-admin/api/org-frame/custom-form'
import PageHeaderContent from '@/modules/organization-admin/components/OrgFrame/Page/PageHeaderContent'
import PageLeft from '@/modules/organization-admin/components/OrgFrame/Page/PageLeft'
import PageRight from '@/modules/organization-admin/components/OrgFrame/Page/PageRight'
import PreviewCodeDialog from '@/modules/organization-admin/components/OrgFrame/Page/PreviewCodeDialog'
import {getActivityFormDetail, setActivityFormDetail,} from '../../api/activity-form'
import {randomString} from '../../../../base/utils/tool'

let library = require('../../data/component-library.json')
const defaultList =
  require('@/modules/organization-admin/assets/jsons/default-data.json').default
let { page, bottomBtn } = JSON.parse(JSON.stringify(library))

export default {
  components: {
    PageMiddle,
    PageRight,
    PageLeft,
    PageHeaderContent,
    PreviewCodeDialog,
  },
  provide() {
    return {
      getList: () => this.main.componentList || []
    }
  },
  data() {
    return {
      loading: false,
      header: {
        isFullscreen: true,
      },
      main: {
        selected: 'page',
        page: JSON.parse(JSON.stringify(page)),
        bottomBtn: JSON.parse(JSON.stringify(bottomBtn)),
        componentList: [],
      },
      qrCodeData: {},
      showPreviewqrCode: false,
      leftBarWidth: '--left-barwidth: ' + '285px' + '; ',

      editId: '' // 保存过后获取
    }
  },
  computed: {
    ...mapState('formPage', ['library']),
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
    isNew() {
      return (this.$route.params.id === 0 || this.$route.params.id === '0' || this.$route.query.id === 0 || this.$route.query.id === '0') && !this.editId
    }
  },
  created() {
    this.$nextTick(() => {
      // 左侧菜单选项-个人资料表单
      this.getUserFormList(() => {
        const { copy_id, title} = this.$route.query

        if (!this.isNew) this.getFormConfig() // 中间的表单
        else if (copy_id) this.getFormConfig(copy_id)

        this.pageTitle = title ? decodeURIComponent(title) : ''
      })
    })
  },
  mounted() {
    /**
     * 从Firefox 4、 Chrome 51、Opera 38 和Safari 9.1开始，通用确认信息代替事件返回的字符串。
     * 也就是不能自定义
     * */
    // 在即将离开当前页面执行
    window.onbeforeunload = function (e) {
      e = e || window.event // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = '当前页面有修改，是否放弃更改？'
      } // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return '当前页面有修改，是否放弃更改？'
    }
  },
  methods: {
    /**
     * 数据统一处理
     * @param list  列表数据
     * @param mode  decode：把数据变成前端想要的样子，encode：把数据变成后端要的
     */
    listAdapter(list, mode = 'decode') {
      let _list = []
      if (mode === 'decode') {
        // 回显数据
        list.forEach((item, index) => {
          _list.push({
            ...item,

            id: item.id || randomString(10),

            ...this.parseFormConfig(item),

            // 旧数据 type 适配
            type: item.type === 'multiSelect' || item.type === 'multi-select' ? 'select' : item.type,

            // 后端需要校验修改状态，如无需要，不要随意修改以下字段
            extra: {
              timestamp: item.extra && item.extra.timestamp ? item.extra.timestamp : new Date().getTime() + index * index,
              type: item.extra && item.extra.type ? item.extra.type : ''
            },
          })
          console.log(item.type)
          console.log('------------')
          this.updateCount(item) // 报告！顺便更新使用数量
        })
      } else {
        _list = JSON.parse(JSON.stringify(list)) // 深拷贝一份
        _list.forEach((item, index) => {
          if (item.type === 'select' && item.is_user_info !== 1) {
            // 活动信息收集旧类型
            if (item.field === 'base-multi-select') {
              item.type = 'multi-select'
            }
          }
        })
      }

      return _list
    },
    // 获取配置详情
    getFormConfig(id) {
      getActivityFormDetail({
        id: id || this.$route.params.id || this.$route.query.id,
      })
        .then(({ data }) => {
          let listData = data.form_config
          this.main.componentList = this.listAdapter(listData, 'decode')
          this.main.page = this.$mergeModel(page, data.page_config);
        })
        .catch(() => {})
    },
    parseFormConfig(obj) {
      if (obj.props === undefined) {
        let formComponent, formTitle, formField, formType
        switch (obj.type) {
          case 'input':
            formComponent = 'st-input'
            formTitle = '文本'
            formField = 'base-input'
            formType = 'input'
            break
          case 'select':
            formComponent = 'st-input'
            formTitle = '单选框'
            formField = 'base-select'
            formType = 'select'
            break
          case 'multi-select':
          case 'multiSelect':
            formComponent = 'st-input'
            formTitle = '多选框'
            formField = 'base-multi-select'
            formType = 'select'
            break
          default:
        }

        // 视为旧数据
        return {
          ...obj,
          field: formField,
          component: formComponent,
          title: formTitle,
          type: formType,
          props: {
            normal: {
              required: obj.required || 0,
              text_limit: obj.text_limit || 0,
              maxlength: obj.text_limit || 0,
              options: obj.options || [],
              label: obj.name || obj.title || '',
              description: obj.description || '',
              inputType: obj.inputType || 'text',
              placeholder: obj.placeholder || (formType === 'input' ? '请输入' : '请选择'),
              value: obj.placeholder || (formType === 'input' ? '请输入' : '请选择')
            },
          },
        }
      }
      return this.syncOldJoinForm(obj)
    },
    /**
     * 数据收集旧字段
     "description": "",
     "name": "",
     "options": [],
     "required": 1,
     "text_limit": 100,
     "timestamp": 0,
     */
    syncOldJoinForm(newForm) {
      const { label, description, options, required, maxlength } = newForm.props.normal
      newForm.name = label || ''
      newForm.description = description || ''
      newForm.options = options || [] // {pay_amount, pay_type, text, timestamp}[]
      newForm.required = required || 0
      newForm.text_limit = maxlength || 0

      newForm.timestamp = newForm.extra?.timestamp || new Date().getTime()
    },
    /**
     * 入会申请表单
     * 写入 library
     */
    getUserFormList(callback) {
      this.loading = true
      allField({
        pageset: 'user',
      })
        .then((res) => {
          const { data } = res
          let componentList = []
          let listData = data.length
            ? data
            : data.configs && data.configs.length
            ? data.configs
            : defaultList
          listData.forEach((item, index) => {
            // if (item.field === 'phone') return
            if (item.field === 'member_type') return
            if (item.field === 'referee') return
            if (item.field === 'position_id') return

            componentList.push({
              ...item,
              title: item.field === 'base-title' ? item.props.normal.value : item.props.normal.label, // 实际使用时的名称
              is_user_info: 1, // 是否用户个人表单信息（0 或 1）
              extra: {
                timestamp: new Date().getTime() + index * index,
                count: 0,
                maxCount: 1,
              },
            })
          })

          this.$store.commit(
            'formPage/setLibrary',
            JSON.parse(
              JSON.stringify({
                ...library,
                user: JSON.parse(JSON.stringify(componentList)),
              })
            )
          )

          this.loading = false

          callback()
        })
        .catch(() => {
          this.loading = false
        })
    },
    async handleHeaderClick(key) {
      switch (key) {
        case 'save': {
          try {
            if (this.isNew) {
              this.$prompt('请输入表单名称', '提示', {
                confirmButtonText: '保存',
                cancelButtonText: '取消',
                // inputPattern: /.+/,
                inputErrorMessage: '请输入表单名称',
                // inputValue: this.pageTitle,
                inputPlaceholder: this.pageTitle
              }).then(async ({ value }) => {
                if (value) this.pageTitle = value
                await this.save()
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消保存'
                });
              });
            } else {
              await this.save()
            }
          } catch (e) {
            console.log(e)
          }
          break
        }
        case 'preview':
        case 'fullscreen':
        default:
      }
    },
    /**
     * 更新组件列表
     */
    updateList(arr) {
      this.main.componentList = arr
    },
    // 保存
    save(isPreview = false) {
      this.loading = true
      const saveData = { ...this.main, componentList: this.listAdapter(this.main.componentList, 'encode') }
      const fieldData = {
        id: this.editId || this.$route.params.id || this.$route.query.id,
        name: this.pageTitle
      }

      let flag = false
      // 表单标题查重
      saveData.componentList.forEach((el1, i) => {
        if (el1.is_user_info !== 1)  this.syncOldJoinForm(el1)

        if (el1.is_user_info === 0) {
          delete el1.is_user_info // 后端通过区别此字段是否存在
        }

        if (el1.field === 'member_type') {
          el1.props.normal.options.forEach((op) => {
            if (op.is_open && op.option_name === '' && !flag) {
              this.main.selected = i
              this.$refs.pageMiddle.errFormConfig(i)
              this.$message.error('会员类型选项文本不能为空')
              this.loading = false
              flag = true
            }
          })
        }
        if (!flag && (el1.field === 'base-select' || el1.field === 'base-multi-select')) {
          if (el1.props.normal.options.length === 0 && !flag) {
            this.main.selected = i
            this.$refs.pageMiddle.errFormConfig(i)
            this.$message.error('选项内容不能为空')
            this.loading = false
            flag = true
          } else el1.props.normal.options.forEach((op) => {
            if (op.text !== undefined && op.text === '' && !flag) {
              this.main.selected = i
              this.$refs.pageMiddle.errFormConfig(i)
              this.$message.error('选项名称不能为空')
              this.loading = false
              flag = true
            }
            if (op.text === undefined && op === '' && !flag) {
              this.main.selected = i
              this.$refs.pageMiddle.errFormConfig(i)
              this.$message.error('选项名称不能为空')
              this.loading = false
              flag = true
            }

            if (!flag) {
              const allOptions = el1.props.normal.options.map(op2 => op2.text !== undefined ? op2.text : op2)
              const uniqueOptions = Array.from(new Set(allOptions)) // 去重

              if (allOptions.length !== uniqueOptions.length) {
                this.main.selected = i
                this.$refs.pageMiddle.errFormConfig(i)
                this.$message.error('选项名称不能重复')
                this.loading = false
                flag = true
              }
            }

           })
        }
        saveData.componentList.forEach((el2, j) => {
          if (
            (el2.props.normal.label || el2.props.title) ===
              (el1.props.normal.label || el1.props.title) &&
            i !== j
          ) {
            if (!flag) {
              this.main.selected = i
              this.$refs.pageMiddle.errFormConfig(i)
              this.$message.error('存在标题重复的表单')
              this.loading = false
              flag = true
            }
          }
        })
      })
      if (flag) {
        // 查重不通过，走 catch
        return Promise.reject()
      }
      // 预览不需要保存，仅查重
      if (isPreview) return Promise.resolve()

      fieldData.form_config = JSON.stringify(saveData.componentList)
      fieldData.page_config = JSON.stringify(saveData.page)

      console.log('save: form_config', saveData.componentList)

      setActivityFormDetail(fieldData)
        .then((res) => {
          if (!isPreview) {
            if (res.code) {
              this.$message.success(res.msg)
              // this.$confirm(res.msg, '', {
              //   confirmButtonText: '关闭页面',
              //   cancelButtonText: '留在此处'
              // }).then(() => {
              //   this.closeWindow()
              // }).catch(() => {})

              this.editId = res.data.id;
              // 可视化区域的标题同步变化
              this.updateLocationSearch({
                title: encodeURIComponent(fieldData.name)
              })
            }

            this.loading = false
          }
          return Promise.resolve()
        })
        .catch(() => {
          this.loading = false
          return Promise.reject()
        })
    },
    /**
     * 不刷新页面修改地址栏参数
     * @param {Object}  query
     * @param {Boolean} force 是否保留已有 currentRoute.query 参数
     */
    updateLocationSearch(query, force = false) {
      let currentQueryFiltered;
      let currentQuery = this.$router.currentRoute.query;

      let { title, ...filtered } = currentQuery;
      currentQueryFiltered = filtered;

      /* 避免重复 replace 相同的路由 */
      if (query.title && query.title === currentQuery.title) {
        return;
      }

      this.$router
          .replace({
            name: "ActivityFormDesign",
            // 原有的保留
            params: this.$router.currentRoute.params,
            // 已有的 query 参数要保留
            query: !force
                ? Object.assign({}, currentQueryFiltered, query)
                : query,
          })
          .catch((err) => {
            console.log(err);
          });
    },
    /**
     * 退出编辑
     */
    closeWindow() {
      window.opener = null;
      window.close();
    },
    /**
     * 修改组件使用次数
     * @param{String} field 组件标识
     */
    updateCount({ field, is_user_info, props }) {
      let updated = false
      /* 组件使用次数+1 */
      let newLibrary = JSON.parse(JSON.stringify(this.library))
      const groups = ['activity', 'user']
      groups.forEach((g) => {
        if (newLibrary[g])
          newLibrary[g].forEach((li) => {
            if (updated) return
            if (field) {
              /**
               * 注意：活动表单和个人资料表单 field 可能重复，还需要结合 is_user_info 判断
               */
              if (li.field === field && is_user_info === li.is_user_info) {
                if (is_user_info) {
                  if (li.title === props.normal.label) {
                    li.extra && li.extra.count++
                    updated = true
                  }
                } else {
                  li.extra && li.extra.count++
                  updated = true
                }
              }
            }
          })
      })
      this.$store.commit('formPage/setLibrary', newLibrary)
    },
  },
}
</script>

<style lang="scss">
.form-page {
  overflow-x: auto;
  margin: calc(-#{$space} * 2);
  background-color: #eef2f8;

  /* 全屏 */
  &.fullscreen {
    position: fixed;
    left: calc(#{$space} * 2);
    top: calc(#{$space} * 2);
    height: 100vh;
    width: 100%;
    z-index: 11;

    .workspace {
      height: calc(100vh - 54px);
    }
  }

  .menu-bar {
    background-color: $primary;
  }

  /* 顶部菜单栏下面的一整个容器 */
  .workspace {
    height: calc(100vh - 60px - 50px - 54px);
  }

  .page-left,
  .page-right {
    background-color: white;
  }

  .workspace-left {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    float: left;
  }

  .el-main.workspace-middle {
    padding: 0;
    overflow: hidden;
  }
}

.resize-save {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
}

.resize-bar {
  width: var(--left-bar-width);
  min-width: var(--left-bar-width);
  max-width: 100%;
  /*height: inherit;*/
  height: calc(100vh - 54px);
  resize: horizontal;
  cursor: col-resize;
  opacity: 0;
  overflow: scroll;
}

/* 拖拽线 */
.resize-line {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 2px solid #eee;
  border-left: 1px solid #bbb;
  pointer-events: none;
}

.resize-bar:hover ~ .resize-line,
.resize-bar:active ~ .resize-line {
  border-left: 1px dashed #3a76ff;
}

.resize-bar::-webkit-scrollbar {
  width: 200px;
  height: inherit;
}

/* Firefox只有下面一小块区域可以拉伸 */
@supports (-moz-user-select: none) {
  .resize-bar:hover ~ .resize-line,
  .resize-bar:active ~ .resize-line {
    border-left: 1px solid #bbb;
  }
  .resize-bar:hover ~ .resize-line::after,
  .resize-bar:active ~ .resize-line::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 0;
    right: -8px;
    /*background: url(./resize.svg);*/
    /*background-size: 100% 100%;*/
  }
}
</style>
