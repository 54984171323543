<template>
  <div
    :style="{
      marginBottom: myConfig.normal.marginBottom + 'px',
      paddingBottom: myConfig.normal.description ? '' : '10px',
    }"
    class="st-title flex-col"
  >
    <p
      :style="[
        myConfig.normal.textConfig,
        {
          fontSize: `${myConfig.normal.fontSize}px`,
        },
      ]"
      class="__content"
    >{{ myConfig.normal.value }}</p>

    <p class="base-form-item-tips __content" v-if="myConfig.normal.description">
      <i class="el-icon-info" />{{ myConfig.normal.description }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'st-title',
  props: {
    config: {
      type: Object,
      default: function () {
        return {}
      },
    },
    type: {
      type: String,
      default: 'input',
    },
    formType: {
      type: String,
      default: 'base-input',
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          validate: '',
          description: '',
          maxlength: 0, // 最大字数
          marginBottom: 1 || 0,
          required: 0,
          label: '标题内容',
          value: '分段标题',
          placeholder: '',
          inputType: 'text',
          showWordLimit: 0,
          fontSize: 16,
          textConfig: {
            color: '#000000',
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecoration: 'none',
            textAlign: 'left',
          },
        },
      },
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
}
</script>

<style lang="scss">
.st-title {
  padding: 10px 18px 0;

  .__content {
    white-space: pre-wrap;
  }

  .base-form-item-tips {
    text-align: justify;
    line-height: 16px;
    font-size: 13px;
    color: #9d9ea0;
    /*padding-top: 7px;*/
    //margin-top: -8px;
    margin-top: 8px;
    padding-bottom: 18px;
    word-break: break-word;

    .el-icon-info {
      margin-right: 5px;
      display: inline-block;
    }
  }
}
</style>
