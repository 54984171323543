import api from "@/base/utils/request";

// 获取页面设置和底部按钮设置
export const systemPageConfig = data => {
  return api({
    url: "/admin/cyc/apply_template/systemPageConfig",
    method: "post",
    data
  });
};

// 保存页面设置和底部按钮设置
export const savePage = data => {
  return api({
    url: "/admin/cyc/apply_template/savePage",
    method: "post",
    data
  });
};

// 自定义表单配置保存
export const saveField = data => {
  return api({
    url: "/admin/cyc/apply_template/saveField",
    method: "post",
    data
  });
};

// 获取全局表单配置
export const allField = data => {
  return api({
    url: "/admin/cyc/apply_template/index",
    method: "post",
    data
  });
};

// 获取架构表单配置
export const templateField = data => {
  return api({
    url: "/admin/cyc/apply_template/templateGlobal",
    method: "post",
    data
  });
};

// 保存架构配置
export const saveTemplate = data => {
  return api({
    url: "/admin/cyc/apply_template/saveTemplate",
    method: "post",
    data
  });
};

// 获取预览二维码
export const getPreviewCode = data => {
  return api({
    url: '/admin/cyc/apply_template/previewField',
    method: 'post',
    data
  })
}

export const getCityData = () => {
  return api({
    url: '/admin/admin/data/getCityJson',
    method: 'post',
    notCancel: true
  })
}
